<template>
  <div>
    <v-card v-show="!showProgresso" max-width="1024" class="mx-auto my-10" style="background-color: #f8f9fa">

      <v-card-title style="background-color: #94896d;>">
        <span class="text-h6" style="color: #ffffff">{{ titulo_formulario }}</span>
      </v-card-title>
      <v-spacer />
      <v-card-text style="margin-top: 20px">
        <v-row>
          <v-col cols="12">
            <v-alert v-if="error != undefined" elevation="2" colored-border
              style="background-color: #990000; color: white">
              {{ error }}
            </v-alert>
          </v-col>
        </v-row>

        <!-- Campos da tabela de preço -->
        <v-row>
          <v-col cols="12">
            <v-text-field type="text" label="Descrição (ex.: faixa x ano 20xx)" v-model="dados.descricao" autofocus
              color="#94896d" dense />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select label="Ano" :items="listaAnos" item-text="ano" item-value="ano" v-model="dados.ano"
              color="#94896d" dense />
          </v-col>

          <v-col cols="4">
            <v-select label="Faixa" :items="listFaixas" item-value="idfaixacontrato" item-text="descricao"
              v-model="dados.idfaixacontrato" @change="buscaVariacaoValorFaixa" dense />
          </v-col>

          <v-col cols="4">
            <v-text-field type="text" label="Valor" v-model="dados.valor"
            dense prepend-icon="mdi-currency-usd" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn color="#f5f4ec" dark class="mb-2" @click="close">
          <span style="color: #990000">Cancelar</span>
        </v-btn>

        <v-btn color="#990000" dark class="mb-2 mr-5" @click="salvar">
          <span style="color: #ffffff">Salvar</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-show="!showProgresso" max-width="1024" class="mx-auto my-10" style="background-color: #f8f9fa">
      <v-card-title style="background-color: #94896d;>">
        <span class="text-h6" style="color: #ffffff">
          Relação dos anos próximos
        </span>
      </v-card-title>
      <v-data-table :headers="headers" :items="listaFaixaAnos" class="elevation-1">
        <template v-slot:[`item.valor`]="{item}">
          <v-icon>mdi-currency-usd</v-icon>
          <span>
            {{ item.valor }}
          </span>
        </template>
      </v-data-table>
    </v-card>

    <!-- Template para o temporizador de tela -->
    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />
  </div>
</template>

<script>

import formatacaoMixin from '@/mixins/formatacao';
import mixin from '@/store/mixin.js';
import { api } from '@/network/Api';
import { getApi, postApi } from '@/network/ApiService';

export default {
  components: {
  },
  created() {
    this.initialize();
  },
  mixins: [formatacaoMixin, mixin],
  data() {
    return {
      idregistro: this.$route.params.id,
      titulo_formulario: 'Edição de tabela de preço',

      listFaixas: [],
      listaAnos: [],
      dados: {
        descricao: '',
        ano: '',
        valor: 0,
        idfaixacontrato: ''
      },
      showProgresso: false,
      apiAtualizarRegistro: api.faixaValorAtualizar,
      nome_rota_listagem_dados: 'TabelasPreco',
      error: undefined,

      headers: [
        { text: 'Ano', align: 'start', sortable: false, value: 'ano' },
        { text: 'Valor', value: 'valor' },
      ],
      listaFaixaAnos: []
    };
  },
  methods: {
    initialize() {
      this.showProgresso = true;
      this.configuraFiltroAno();
      this.consultarFaixas();
      getApi(api.faixaValorConsultar, this.idregistro)
        .then((res) => {
          this.dados.descricao = res.data.descricao;
          this.dados.ano = res.data.ano;
          this.dados.valor = this.formataDinheiro(res.data.valor);
          this.dados.idfaixacontrato = res.data.idfaixacontrato;

          this.buscaVariacaoValorFaixa();
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        })
        .catch((err) => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        });
    },
    configuraFiltroAno() {
      var dataAtual = new Date();
      var anoAtual = dataAtual.getFullYear();
      this.anoSelecionado = anoAtual;
      var anos = [];
      // Adicionar -10 / atual / +5 anos
      for (var i = anoAtual - 10; i < anoAtual; i++) {
        anos.push(i);
      }
      anos.push(anoAtual);
      for (var j = anoAtual + 1; j <= anoAtual + 5; j++) {
        anos.push(j);
      }
      this.listaAnos = anos;
    },

    consultarFaixas() {
      getApi(api.faixaContratoListarTodas)
        .then((res) => {
          this.listFaixas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    close() {
      this.dialog = false;
      this.clearForm();
      this.initialize();
    },
    salvar() {
      this.error = null;

      if (this.dados.descricao == '') {
        this.error = 'O campo descrição é obrigatório';

        setTimeout(() => {
          this.error = undefined;
        }, 2000);

        return;
      } else if (this.dados.ano == '') {
        this.error = 'O campo ano é obrigatório';

        setTimeout(() => {
          this.error = undefined;
        }, 2000);

        return;
      } else {
        postApi(this.apiAtualizarRegistro, this.idregistro, {
          descricao: this.dados.descricao,
          ano: this.dados.ano,
          valor: parseFloat(this.dados.valor.replace(',', '.')),
          idfaixacontrato: this.dados.idfaixacontrato,
        })
          .then((res) => {
            if (res.data === 'Tudo ok!') {
              (this.dados.descricao = ''),
                (this.dados.ano = ''),
                (this.dados.valor = ''),
                (this.listFaixasTabelaPreco = []);
            }
            this.error = undefined;
            this.close();
          })
          .catch((err) => {
            var msgErro = err.response.data.err;
            this.error = msgErro;
          });

      }
    },
    close() {
      this.$router.push({ name: this.nome_rota_listagem_dados });
    },
    buscaVariacaoValorFaixa() {
      let id = this.dados.idfaixacontrato;
      if (id != null && id != '') {
        getApi(api.faixaValorConsultarVariacao, id)
          .then((res) => {
            this.listaFaixaAnos = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
};
</script>

<style scoped></style>
