<template>
  <div class="mx-12">
    <v-card v-show="!showProgresso" class="mx-auto my-12" style="background-color: #f8f9fa">
      <!-- Título de identificação da tela -->
      <v-card-title style="padding:12px 24px; margin-bottom: 45px">
        <v-row>
          <v-col cols="12">
            <h5 style="color: #94896d">{{ titulo_listagem }}</h5>
            <v-divider />
          </v-col>
        </v-row>
      </v-card-title>

      <v-row style="padding: 2rem 3rem;">
        <v-col cols="10">
          <slot name="filtros"></slot>
        </v-col>
        <v-col cols="2">
          <v-row align-self="center" no-gutters style="height: 100%;">
            <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" style="display: flex; align-items: end">
              <v-btn block elevation="4" x-large color="#990000" dark @click="loadData">
                <span style="color: #ffffff">Consultar</span>
              </v-btn>
            </v-col>
          </v-row>
          <!-- <slot name="filtros-acao"></slot> -->
        </v-col>
      </v-row>

      <v-card-title style="margin-bottom: 25px; padding: 12px 24px;">
        <v-row style="display: flex;">
          <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
            <!-- Campo para pesquisar -->
            <v-text-field dense v-model="search" prepend-inner-icon="mdi-magnify" label="Pesquisa rápida" clearable
              solo-inverted hide-details color="#FFFFFF" style="background-color: #f5f4ec" />
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" style="display: flex; align-items: end">
            <!-- Botão para chamar o formulário de cadastro -->
            <v-btn color="#990000" dark @click="cadastrar" v-show="mostra_botao_cadastrar">
              <span style="color: #ffffff">Cadastrar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <slot name="topo"></slot>

      <!-- Tabela para exibir os registros cadastrados -->
      <v-data-table :headers="cabecalhos_colunas" :items="registros" :search="search" :sort-by="coluna_ordenar"
        class="elevation-1" style="background-color: #f8f9fa">
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="column in cabecalhos_colunas" :key="column.value">

              <!-- Botões para manipular os registros da listagem(Edição e Exclusão) -->
              <span v-if="column.type == 'actions'">
                <!-- Botão que chama o formulário de edição -->

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small @click="editarItem(item)" class="mr-2" color="#344767" v-bind="attrs" v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <!-- Botão para execluir um registro da listagem -->
                <v-tooltip v-if="pode_excluir" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small @click="deleteItem(item)" color="#344767" v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </span>
              <span v-else-if="column.type == 'date'">
                {{ formataData(item, column) }}
              </span>
              <span v-else-if="column.type == 'money'">
                {{ formataDinheiro(item, column) }}
              </span>
              <span v-else>
                {{ formataColuna(item, column) }}
              </span>
            </td>
          </tr>
        </template>

        <!-- Caixa de diálogo para confirmação da exclusão -->
        <template v-slot:[`top`]>
          <v-dialog v-model="dialogDelete" max-width="555px">
            <v-card>
              <v-card-title class="text-h6">
                Tem certeza que deseja excluir este registro?
              </v-card-title>

              <!-- Botões para cancelamento ou confirmação da exclusão -->
              <v-card-actions>
                <v-spacer />

                <v-btn color="#f5f4ec" dark @click="dialogDelete = false">
                  <span style="color: #990000">Não</span>
                </v-btn>

                <v-btn color="#990000" dark @click="deleteItemConfirm">
                  <span style="color: #ffffff">Sim</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>

    <!-- Template para o temporizador de tela -->
    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />

    <!-- Caixa de diálogo para exibir mensagens -->
    <dialog-msg-alerta tituloDialogMsgAlerta="Atenção!" :mensagemAlerta="mensagemAlerta"
      :showMensagem="dialogMensagemAlerta">
    </dialog-msg-alerta>
  </div>
</template>

<script>

import formatacaoMixin from '@/mixins/formatacao';
import DialogMsgAlerta from '@/components/dialogs/DialogMsgAlerta.vue';
import { getApi, excluirApi } from '@/network/ApiService';

export default {

  mixins: [formatacaoMixin],
  components: {
    DialogMsgAlerta,
  },

  props: {
    coluna_ordenar: {
      type: String,
      required: false,
    },
    id_registro: {
      type: String,
      required: true,
    },
    titulo_listagem: {
      type: String,
      required: false,
    },
    apiConsultarRegistros: {
      type: String,
      required: true,
    },
    apiExcluirRegistro: {
      type: String,
      required: true,
    },
    formataColuna: {
      type: Function,
      default: (item, column) => item[column.value],
    },
    pode_excluir: {
      type: Boolean,
      default: true,
    },
    mostra_botao_cadastrar: {
      type: Boolean,
      default: true,
    },
    filtros: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      cabecalhos_colunas: [],
      dialogMensagemAlerta: false,
      mensagemAlerta: '',
      search: '',
      dialogDelete: false,
      registros: [],
      totalRegistros: 0,
      primarykey: -1,
      showProgresso: false,
    };
  },
  methods: {
    loadData() {
      this.showProgresso = true;

      getApi(this.apiConsultarRegistros, null, this.filtros)
        .then((res) => {
          this.registros = res.data.dados;
          this.cabecalhos_colunas = res.data.header;
          this.totalRegistros = this.registros.length;
        })
        .catch((err) => { })
        .finally(() => {
          setTimeout(() => { this.showProgresso = false; }, 100);
        });
    },
    deleteItem(item) {
      let editedItem = Object.assign({}, item);
      this.primarykey = editedItem[this.id_registro];
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // Exclui registro no banco de dados
      excluirApi(this.apiExcluirRegistro, this.primarykey)
        .then(() => {
          this.closeDelete();
        })
        .catch(err => {
          let textoErro = err.response.data.detail;
          let posicao = textoErro.search('is still referenced from table');
          if (posicao > 0) {
            this.mensagemAlerta = 'Este registro não pode ser excluído!';
          } else {
            this.mensagemAlerta = 'Erro ao excluir, tente novamente mais tarde!';
          }
          this.dialogMensagemALerta = true;
          this.closeDelete();
        });
    },
    close() {
      this.primarykey = -1;
      this.clearForm();
      this.loadData();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.primarykey = -1;
      this.loadData();
    },
    cadastrar() {
      this.$emit('cadastrarItem');
    },
    editarItem(item) {
      let editedItem = Object.assign({}, item);
      this.primarykey = editedItem[this.id_registro];
      this.$emit('editarItem', { id: this.primarykey });
    },
  },
};
</script>