import { realizarLogin, realizarLogout } from '@/network/ApiService';

export default {
  data() {
    return {
      alert: {
        show: false,
        msg: '',
        typeAlert: 'success'
      },
    }
  },
  methods: {
    // Função para logar
    login(pUsuario, pSenha) {
      this.desativaBotaoLogar = true;
      if (pUsuario !== '' && pSenha !== '') {
        realizarLogin(pUsuario.trim(), pSenha.trim())
          .then(res => {
            this.loginMessage('success');
            setTimeout(() => {
              this.$router.push({ path: '/home', meta: { isAuth: true } })
                .catch(() => { this.loginMessage(500); });
            }, 1000)
          })
          .catch(err => {
            this.loginMessage(err.status, err.message);
            this.desativaBotaoLogar = false;
          })
      } else {
        this.loginMessage(500);
      }
    },
    logout() {
      realizarLogout()
        .then(res => {
          this.$router.push({ path: '/login', meta: { isAuth: false } }).catch(() => { this.loginMessage(500); });
        })
        .catch(err => {
          if (err.message != null && err.message != undefined) {
            this.loginMessage(err.status, err.message);
            this.desativaBotaoLogar = false;
          }
        })
    },
    // Função para exibir mensagem de login, tanto para sucesso quanto para erro
    loginMessage(login, message = '') {
      this.alert.show = true
      clearTimeout()
      switch (login) {
        case 'success':
          this.alert.typeAlert = "success"
          this.alert.msg = 'Login realizado com sucesso!'
          break
        case 'logoff':
          this.alert.typeAlert = "success"
          this.alert.msg = 'Logoff realizado com sucesso!'
          break
        case 401:
          this.alert.typeAlert = "error"
          this.alert.msg = 'Usuário ou senha inválidos!'
          break
        case 404:
          this.alert.typeAlert = "error"
          this.alert.msg = 'Usuário ou senha inválidos!'
          break
        case 500:
          this.alert.typeAlert = "error";
          this.alert.msg = message != '' ? message : 'Preencher os campos para login!';
          break
        case 'expired':
          this.alert.typeAlert = "error"
          this.alert.msg = 'Sua sessão expirou!'
          break
        case 'message':
          this.alert.typeAlert = "error"
          this.alert.msg = message
          break
      }
      setTimeout(() => {
        this.alert.show = false
      }, 3000)
    },
    executaMenu(pMenu) {
      this.$router.push({ name: pMenu });
    },   
    validaEmail() {
      return [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email inválido'
      ];
    },
  }
}


