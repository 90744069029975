<template>
    <div class="login-view">
        <v-row>
            <v-col cols="12">
                <login-mobile 
                    v-if="$vuetify.breakpoint.xs"
                    :texto_abaixo_logo="tituloPagina"
                    :img_logo="require('@/assets/img/logo_ieclb_mobile.png')"
                ></login-mobile>                    

                <login-web 
                    v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
                    :texto_abaixo_logo="tituloPagina"
                    :img_logo="require('@/assets/img/logo_ieclb.png')"
                ></login-web>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import LoginWeb from '@/views/autenticacao/login/LoginWeb.vue';
    import LoginMobile from '@/views/autenticacao/login/LoginMobile.vue';

    export default {
        components: {
            LoginWeb,
            LoginMobile
        },
        data() {
            return {
                tituloPagina: ['Comunidade Evangélica de Confissão Luterana', 'Santa Cruz - Centro']
            }
        },
    }
</script>

<style scoped>
    /* Estilos para dispositivos móveis */
    @media (max-width: 768px) {
        .login-view {
            display: flex;
            align-items: center;
            height: 100vh;
            background-image: url('@/assets/img/igreja.jpg');
            background-color: #f1f1f1;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
    }

    /* Estilos para resolução web (largura de tela maior que 768 pixels) */
    @media (min-width: 769px) {
        .login-view {
            display: flex;
            align-items: center;
            height: 100vh;
            background-image: url('@/assets/img/igreja.jpg');
            background-color: #f1f1f1;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }

    .bloco-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 150px;
    }

    .bloco-logo-mobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100px;
    }

    .custom-icon-color .v-icon {
        color: #0044ff!important;
    }
</style>