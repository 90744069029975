<template>
  <div>
    <insere-dados-v2 v-show="!showProgresso" :titulo_formulario="titulo_formulario"
      :apiInserirRegistro="apiInserirRegistro" :campos_formulario="dados" @saveCompleted="saveCompleted" @close="close">
      <v-row>
        <v-col cols="12">
          <v-text-field type="text" label="Descrição:" v-model="dados.descricao" autofocus color="#94896d" filled />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-autocomplete filled label="Pessoa" :items="listaPessoas" item-value="idpessoa" item-text="nome"
            v-model="dados.idpessoa" color="#94896d" clearable />
        </v-col>
        <v-col cols="4">
          <v-select label="Tipo do pagamento" :items="listaTipoPagamento" item-value="valor" item-text="descricao"
            v-model="dados.tipo" color="#94896d" filled />
        </v-col>
        <v-col cols="4">
          <v-text-field type="text" prefix="R$" v-money="money" label="Valor" v-model="dados.valor" autofocus
            color="#94896d" filled />
        </v-col>

        <!-- <v-col cols="4">
          <v-select ref="contrato" label="Selecionar o contrato:" :items="listaContratos" item-value="idcontrato"
            item-text="contrato" v-model="dados.idcontrato" color="#94896d" dense />
        </v-col> -->
        <!-- <v-col cols="4">
          <v-select label="Selecionar a  ontrato renovacao:" :items="listaContratoRenovacao"
            item-value="idcontratorenovacao" item-text="descricao" v-model="dados.idcontratorenovacao" color="#94896d"
            dense />
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select label="Forma de pagamento:" :items="listaFormasPagamento" item-value="idformapagamento"
            item-text="descricao" v-model="dados.idformapagamento" color="#94896d" filled />
        </v-col>
        <v-col cols="4">
          <v-select label="Conta" :items="listaContas" item-value="idconta" item-text="descricao"
            v-model="dados.idconta" color="#94896d" filled />
        </v-col>
        <v-col cols="4">
          <v-select label="Caixa" :items="listaCaixas" item-value="idcaixa" item-text="descricao"
            v-model="dados.idcaixa" color="#94896d" filled />
        </v-col>
        <!-- <v-col cols="4">
          <v-text-field type="text" label="Parcela" v-model="dados.numero_parcelas" autofocus color="#94896d" filled />
        </v-col>
        <v-col cols="4">
          <v-text-field type="text" label="Parcela Final" v-model="dados.parcela_final" autofocus color="#94896d"
            filled />
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field type="text" label="Documento" v-model="dados.documento" autofocus color="#94896d" filled />
        </v-col>
        <v-col cols="4">
          <v-text-field type="text" label="Número de Série" v-model="dados.numero_serie" autofocus color="#94896d"
            filled />
        </v-col>
        <v-col cols="4">
          <v-select label="Status" disabled :items="listaStatus" item-value="valor" item-text="descricao"
            v-model="dados.status" color="#94896d" filled />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <CampoData titulo="Data Movimentacao" v-model="dados.data_movimentacao" />
        </v-col>
        <!-- <v-col cols="4">
          <CampoData titulo="Data Pagamento" v-model="dados.data_pagamento" />
        </v-col> -->
        <v-col cols="6">
          <CampoData titulo="Data Vencimento" v-model="dados.data_vencimento" />
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="4">
          <v-text-field type="text" label="Desconto:" v-model="dados.desconto" autofocus color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <v-text-field type="text" label="Acréscimo:" v-model="dados.acrescimo" autofocus color="#94896d" dense />
        </v-col>
       
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field type="text" label="* Observação:" v-model="dados.observacao" autofocus color="#94896d" dense />
        </v-col>
      </v-row> -->

      <!-- <v-row>
        <v-col cols="4">
          <CampoData titulo="Data Programada" v-model="dados.data_programado" />
        </v-col>
       
        <v-col cols="4">
          <CampoData titulo="Data Entrada" v-model="dados.data_entrada" />
        </v-col>
      </v-row> -->

      <!-- <v-row>
        <v-col cols="4">
          <v-text-field disabled type="text" label="Valor Original" v-model="dados.valor_original" autofocus
            color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <v-text-field disabled type="text" label="Valor Efetivo" v-model="dados.valor_efetivo" autofocus
            color="#94896d" dense />
        </v-col>
        <v-col cols="4">
          <v-text-field disabled type="text" label="Valor Restante" v-model="dados.valor_restante" autofocus
            color="#94896d" dense />
        </v-col>
      </v-row>
 -->

      <!-- <v-row>
        <v-col cols="4">
          <v-select label="Selecionar a transferencia:" :items="listaTransferencias" item-value="idtransferencia"
            item-text="descricao" v-model="dados.idtransferencia" color="#94896d" dense />
        </v-col>
      </v-row> -->
    </insere-dados-v2>
    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import { format, parseISO } from 'date-fns';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';
import InsereDadosV2 from '@/components/crud/InsereDadosV2.vue';

export default {
  created() {
    this.initialize();
  },
  mixins: [mixin],
  components: {
    InsereDadosV2,
  },
  data() {
    return {
      titulo_formulario: 'Novo Pagamento',
      apiInserirRegistro: api.pagamentoCriar,
      dados: {
        idformapagamento: '',
        descricao: '',
        valor: 0,
        tipo: '',
        idconta: '',
        idcaixa: '',
        // idcontrato: '',
        numero_parcelas: '1',
        // pc_desconto: '',
        // pc_acrescimo: '',
        // desconto: '',
        // acrescimo: '',
        observacao: '',
        data_movimentacao: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        data_vencimento: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        // data_pagamento: '',
        // data_entrada: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        // data_programado: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        // valor_efetivo: '',
        numero_pagamento: '',
        // valor_restante: '',
        // idtransferencia: '',
        documento: '',
        numero_serie: '',
        // valor_original: '',
        status: false,
        parcela_final: '1',
        // idcontratorenovacao: '',
        idpessoa: '',
      },
      showProgresso: true,
      listaFormasPagamento: [],
      listaContas: [],
      listaCaixas: [],
      listaContratos: [],
      listaTipoPagamento: [
        { valor: 'E', descricao: 'Entrada' },
        { valor: 'S', descricao: 'Saída' },
      ],
      listaStatus: [
        { valor: true, descricao: 'Pago' },
        { valor: false, descricao: 'Aberto' },
      ],
      listaTransferencias: [],
      listaContratoRenovacao: [],
      listaPessoas: [],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },

  methods: {
    async initialize() {
      this.showProgresso = true;

      let resFP = await getApi(api.formaPagamentoListarTodas);
      this.listaFormasPagamento = resFP.data;


      let resP = await getApi(api.pessoaListarTodas);
      this.listaPessoas = resP.data;

      let resC = await getApi(api.contaListarTodas);
      this.listaContas = resC.data;

      let resCx = await getApi(api.caixaListarTodas)
      this.listaCaixas = resCx.data;

      this.showProgresso = false;
    },

    saveCompleted(dados) {
      this.$router.push({ name: 'PagamentoEdit', params: { id: dados.idpagamento } });
    },
    close() {
      this.$router.push({ name: 'Pagamentos' });
    },

  }
}
</script>

<style scoped></style>