import { render, staticRenderFns } from "./InsertTabelaPrecoView.vue?vue&type=template&id=5e74c27a&scoped=true"
import script from "./InsertTabelaPrecoView.vue?vue&type=script&lang=js"
export * from "./InsertTabelaPrecoView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e74c27a",
  null
  
)

export default component.exports