<template>
  <div>
    <edita-dados
      :idregistro="idregistro"
      :titulo_formulario="titulo_formulario"
      :apiAtualizarRegistro="apiAtualizarRegistro"
      :campos_formulario="formulario"
      :showProgresso="showProgresso"
      @saveCompleted="saveCompleted"
      @close="close"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            type="text" 
            :label="formulario.name.label" 
            v-model="formulario.name.valor"
            autofocus
            color="#94896d" 
            :rules="nomeRules"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field 
            type="text"
            :label="formulario.email.label"
            v-model="formulario.email.valor"
            autofocus
            color="#94896d"
            :rules="emailRules"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field 
            :type="showPassword ? 'text' : 'password'" 
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="formulario.password.valor"
            :label="formulario.password.label" 
            @click:append="togglePasswordVisibility"
            autofocus
            color="#94896d"
            :rules="passwordRules"
          />
        </v-col>

        <v-col cols="12">
      
        <v-select 
          :label="formulario.idperfil.label"
          item-color="#7513BF" 
          clearable 
          :items="perfis" 
          item-text="descricao" 
          item-value="idperfil"
          v-model="formulario.idperfil.valor"
          :rules="perfilRules" 
        />
      </v-col>
      <v-col cols="12">
        
          <v-select 
           :label="formulario.ativo.label"
           v-model="formulario.ativo.valor" 
            item-color="#7513BF" 
            clearable 
            :items="listaAtivo"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
    </edita-dados>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import EditaDados from '@/components/crud/EditaDados.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';

export default {
  mixins: [mixin],
  components: {
    EditaDados,
  },
  data() {
    return {
      idregistro: String(this.$route.params.id),
      titulo_formulario: 'Edição de usuário',
      apiAtualizarRegistro: api.usuarioAtualizar,
      showPassword: false,
      formulario: {
        name: { label: 'Nome', nome_campo: 'name', valor: '' },
        email: { label: 'E-mail', nome_campo: 'email', valor: '' },
        password: { label: 'Senha', nome_campo: 'password', valor: '' },
        idperfil: { label: 'Perfil', nome_campo: 'idperfil', valor: '' },
        ativo: { label: 'Ativo', nome_campo: 'ativo', valor: '' },
      },
      perfis: [],
      listaAtivo: [
        { value: 0, text: 'Não' },
        { value: 1, text: 'Sim' },
      ],
      showProgresso: false,
    };
  },
  
  computed: {
    nomeRules() {
      return [
        v => !!v || "O nome é obrigatório",
        v => !v || v.length > 3 || 'O Nome deverá possuir ao menos 3 caracteres'
      ];
    },
    emailRules() {
      return this.validaEmail();
    },
    passwordRules() {},
    perfilRules() {
      return [
        v => !!v || "O perfil é obrigatório",
      ];
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.showProgresso = true;
        let resultPerfil = await getApi(api.perfilListarTodos);
        this.perfis = resultPerfil.data;

        let resultUsuario = await getApi(
          api.usuarioConsultar,
          this.$route.params.id
        );

        this.formulario.name.valor = resultUsuario.data.name;
        this.formulario.email.valor = resultUsuario.data.email;
        this.formulario.idperfil.valor = resultUsuario.data.idperfil;
        this.formulario.ativo.valor = resultUsuario.data.ativo;
        this.showProgresso = false;
      } catch (erro) {
        this.showProgresso = false;
      }
    },
    saveCompleted(item) {
      this.$router.push({ name: 'Usuarios' });
    },
    close() {
      this.$router.push({ name: 'Usuarios' });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped></style>