<template>
  <div>
    <div class="mx-12">
      <v-card v-show="!showProgresso" class="mx-auto my-12" style="background-color: #f8f9fa">
        <v-card-title style="padding:12px 24px; margin-bottom: 45px">
          <v-row>
            <v-col cols="12">
              <h5 style="color: #94896d">{{ tituloListagem }}</h5>
              <v-divider />
            </v-col>
          </v-row>
        </v-card-title>

        <v-row style="padding: 2rem 3rem;">
          <v-col cols="10">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <CampoData titulo="Data final" v-model="filtros.data" />
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-select v-model="filtros.idconta" color="blue" hide-details :items="listaContas" item-text="descricao"
                  item-value="idconta" label="Conta" persistent-hint clearable />
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-autocomplete v-model="filtros.idpessoa" color="blue" hide-details :items="listaPessoas"
                  item-text="nome" item-value="idpessoa" label="Pessoa" persistent-hint clearable />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row align-self="center" no-gutters style="height: 100%;">
              <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" style="display: flex; align-items: end">
                <v-btn block elevation="4" x-large color="#990000" dark @click="buscarDados">
                  <span style="color: #ffffff">Consultar</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-title style="margin-bottom: 25px; padding: 12px 24px;">
          <v-row style="display: flex;">
            <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
              <!-- Campo para pesquisar -->
              <v-text-field dense v-model="search" prepend-inner-icon="mdi-magnify" label="Pesquisa rápida" clearable
                solo-inverted hide-details color="#FFFFFF" style="background-color: #f5f4ec" />
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table :headers="cabecalhos_colunas" :items="registros" :search="search" :sort-by="coluna_ordenar"
          class="elevation-1" style="background-color: #f8f9fa" fixed-header height="40vh">
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="column in cabecalhos_colunas" :key="column.value">
                <span :style="getStyle(item[column.value], column)">
                  {{ formatValue(item[column.value], column) }}
                </span>
                <span v-if="column.type == 'actions'">
                  <v-icon @click="abrirDetalhamento(item)">mdi-receipt-text-arrow-right-outline</v-icon>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider />
      </v-card>

      <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />
    </div>
    <livro-caixa-detalhe :dialogo-visivel="dialogoDetalhesVisivel" :filtros="filtrosDetalhamento" :saldoAnterior="saldoAnterior" :saldoFinal="saldoFinal"
      @fecharDialogo="fecharDetalhamento" />
  </div>
</template>

<script>

import formatacaoMixin from '@/mixins/formatacao';
import RelatorioView from '@/components/relatorios/RelatorioView.vue';
import visualizaDadosListagem from '@/components/crud/VisualizaDadosListagem.vue';
import LivroCaixaDetalhe from '@/views/LivroCaixa/LivroCaixaDetalhe.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';
import { format, parseISO } from 'date-fns'

export default {
  components: {
    RelatorioView,
    visualizaDadosListagem,
    LivroCaixaDetalhe,
  },
  mixins: [formatacaoMixin, formatacaoMixin],
  data() {
    return {
      tituloListagem: 'Livro Caixa',
      search: '',
      coluna_ordenar: 'data_movimentacao',
      apiContas: api.contaListarTodas,
      apiConsultarRegistros: api.livroCaixa,

      filtros: {
        data: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        idpessoa: '',
        idconta: '',
      },
      filtrosDetalhamento: [],
      registros: [],
      listaPessoas: [],
      listaContas: [],
      cabecalhos_colunas: [],
      showProgresso: true,
      dialogoDetalhesVisivel: false,
      dialogoDetalhesData: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      saldoAnterior: 0,
      saldoFinal: 0,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await Promise.all([this.buscarContas(), this.buscarPessoas()]);
      setTimeout(() => { this.showProgresso = false; }, 100);
    },

    async buscarPessoas() {
      let res = await getApi(api.pessoaListarTodas);
      this.listaPessoas = res.data;
    },
    async buscarContas() {
      let res = await getApi(api.contaListarTodas)
      this.listaContas = res.data;
    },
    buscarDados() {
      getApi(this.apiConsultarRegistros, null, this.filtros)
        .then((res) => {
          this.registros = res.data.dados;
          this.cabecalhos_colunas = [...res.data.header, { text: 'Ações', value: 'ação', 'type': 'actions' }];
        })
        .catch((err) => { })
        .finally(() => {

        });
    },

    abrirDetalhamento(item) {
      this.filtrosDetalhamento = this.filtros;
      this.filtrosDetalhamento.data = item.data;
      this.dialogoDetalhesVisivel = true;
      this.saldoAnterior = item.saldo_inicio_dia;
      this.saldoFinal = item.saldo_fim_dia;
    },
    fecharDetalhamento() {
      this.dialogoDetalhesVisivel = false;
    },

    getStyle(value, column) {
      if (['pagamento_entrada', 'transacao_entrada'].includes(column.value) && value > 0) {
        return { color: 'forestgreen' };
      } else if (['pagamento_saida', 'transacao_saida'].includes(column.value) && value > 0) {
        return { color: 'red' };
      } else if (['saldo_fim_dia', 'saldo_inicio_dia', 'saldo'].includes(column.value) && value > 0 ) {
        return { color: 'forestgreen' };
      } else if (['saldo_fim_dia', 'saldo_inicio_dia', 'saldo'].includes(column.value) && value < 0) {
        return { color: 'red' };
      } else {
        return { color: 'grey' };
      }
      return {};
    },
    formatValue(value, column) {
      if (column.type === 'date') {
        return this.formataData(value);
      } else if (column.type === 'money') {
        return this.formataDinheiro(value);
      }
      return value;
    },
  },

};
</script>

<style scoped></style>
