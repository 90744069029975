<template>
  <div>
    <v-row class="titulo__historico" justify="space-between">
      <v-col cols="6">
        Histórico de Renovação de Contrato
      </v-col>
      <v-spacer />
      <v-col v-if="contrato.status == 1" cols="3" style="text-align: right">
        <v-btn style="font-size: 14px; padding-right: 12px" @click="gerarRenovacaoParcela">
          <v-icon>mdi-shape-square-rounded-plus</v-icon>
          <span>Renovar Contrato</span>
        </v-btn>
      </v-col>
    </v-row>
    
    <v-data-table :headers="titulos" :items="listaHistorico" :items-per-page="20" class="elevation-1">
      <template v-slot:[`item.dt_validade_inicio`]="{ item }">
        {{ formataData(item.dt_validade_inicio) }}
      </template>
      <template v-slot:[`item.dt_validade_fim`]="{ item }">
        {{ formataData(item.dt_validade_fim) }}
      </template>
      <template v-slot:[`item.dt_assinatura`]="{ item }">
        {{ formataData(item.dt_assinatura) }}
      </template>
      <template v-slot:[`item.valor_total`]="{ item }">
        {{ formataDinheiro(item.valor_total) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon x-small :loading="loading"  class="mr-2" @click="imprimir(item)">
          <v-icon small>mdi-printer-outline</v-icon>
        </v-btn>

        <v-btn icon x-small class="mr-2" @click="abrirPagamento(item)">
          <v-icon small>mdi-open-in-new</v-icon> 
        </v-btn>
      </template>
    </v-data-table>

    <contrato-historico-renovacao class="mb-2" :dialogoVisivel="dialogoRenovacao" :contrato="contrato" @fecharRenovacao="fecharRenovacao" />
  
  </div>
</template>

<script>
  import formatacaoMixin from '@/mixins/formatacao';
  import ContratoHistoricoRenovacao from '@/views/contrato/historico/ContratoHistoricoRenovacao.vue';
  import geradorPDF from '@/mixins/geradorPDF';
  import { api } from '@/network/Api';
 
  export default {
    components: { ContratoHistoricoRenovacao },
    mixins: [formatacaoMixin, geradorPDF],
    props: {
      contrato: {
        type: Object,
        require: true,
      },
    },
    data() {
      return {
        loading: false,
        titulos: [
          {
            text: 'Ordem',
            align: 'start',
            sortable: true,
            value: 'ordem',
          },
          {
            text: 'Data Assinatura',
            align: 'start',
            sortable: true,
            value: 'dt_assinatura',
          },
          {
            text: 'Validade Inicio',
            align: 'start',
            sortable: true,
            value: 'dt_validade_inicio',
          },
          {
            text: 'Validade Fim',
            align: 'start',
            sortable: true,
            value: 'dt_validade_fim',
          },
          {
            text: 'Valor total', 
            value: 'valor_total',
          },
          {
            text: 'Observação',
            align: 'start',
            sortable: true,
            value: 'observacao',
          },
          {
            text: 'Ações',
            align: 'end',
            sortable: false,
            value: 'actions',
          },
        ],
        dialogoRenovacao: false,
        listaHistorico: [],
      };
    },
    
    mounted() {
      this.listaHistorico = this.contrato.historico;
    },

    methods: {
      abrirPagamento(item) {
        const routeData = this.$router.resolve({path: `/contrato/historico/${item.idcontratohistorico}`});
        window.open(routeData.href, '_blank');
      },
      async imprimir(item) {
        this.loading = true;
        await this.abirPDF(api.contratoHistoricoRelatorio,  item.idcontratohistorico);
        this.loading = false;
      },
      gerarRenovacaoParcela() {
        this.dialogoRenovacao = true;
      },
      fecharRenovacao() {
        this.dialogoRenovacao = false;
        setTimeout(()=> {window.location.reload();}, 100)
      },
    },
  };
</script>

<style scoped>
  .titulo__historico {
    font-size: 1.4rem;
    padding: 4px;
    margin: 0;
    background-color: #928a6dd9;
    color: #ffffff;
  }
</style>
