<template>
  <div>
    <v-card class="mx-auto my-10" style="background-color: #f8f9fa">
      <v-card-text class="py-0">
        <v-container>
          <!-- <v-row v-if="error != undefined">
            <v-col cols="12">
              <v-alert color="#FBCC38" elevation="2" colored-border>
                {{ error }}
              </v-alert>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <slot name="formulario"></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer />
        <slot name="acoes"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  export default {};
</script>

<style scoped>
  .progresso-circular {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: -200px;
    padding: 0px;
  }
</style>
