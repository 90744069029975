<template>
  <div>
    <edita-dados-v2 :idregistro="idregistro" :titulo_formulario="titulo_formulario"
      :apiAtualizarRegistro="apiAtualizarRegistro" :campos_formulario="dados" :showProgresso="showProgresso"
      @saveCompleted="saveCompleted" @close="close">


      <div v-if="dados.url_logo"
        style="display: flex; justify-content: center; align-items: center; max-width: 90px; max-height: 180px; padding: 5px; border: 1px solid #000;">
        <v-img max-height="130" max-width="60" :src=dados.url_logo />
      </div>

      <v-file-input placeholder="Anexar logo" label="Alterar arquivo" prepend-icon="mdi-paperclip"
        @change="atualizarLogo" accept="image/*">
        <template v-slot:selection="{ text }">
          <v-chip small label color="primary">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>


      <div v-if="dados.url_extra1"
        style="display: flex; justify-content: center; align-items: center; max-width: 90px; max-height: 180px; padding: 5px; border: 1px solid #000;">
        <v-img max-height="130" max-width="60" :src=dados.url_extra1 />
      </div>

      <v-file-input placeholder="Anexar logo" label="Alterar arquivo" prepend-icon="mdi-paperclip"
        @change="atualizarExtra1" accept="image/*">
        <template v-slot:selection="{ text }">
          <v-chip small label color="primary">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>

      <v-text-field label="Nome" type="text" v-model="dados.nome" autofocus color="#94896d" />
      <v-text-field label="CNPJ" type="text" v-model="dados.cnpj" v-mask="'##.###.###/####-##'" color="#94896d" />
      <v-text-field label="Endereço" type="text" v-model="dados.endereco" color="#94896d" />
      <v-text-field label="Celular" type="text" v-model="dados.celular" v-mask="['(##) #####-####']" color="#94896d" />
      <v-text-field label="Telefone" type="text" v-model="dados.telefone" v-mask="['(##) ####-####']" color="#94896d" />
      <v-text-field label="E-mail" type="text" v-model="dados.email" color="#94896d" :rules="emailRules" />

    </edita-dados-v2>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import EditaDadosV2 from '@/components/crud/EditaDadosV2';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';

export default {
  created() {
    this.initialize();
  },
  mixins: [mixin],
  components: {
    EditaDadosV2
  },
  data() {
    return {
      idregistro: this.$route.params.id,
      titulo_formulario: 'Edição de comunidade',
      apiAtualizarRegistro: api.comunidadeAtualizar,

      dados: {
        nome: '',
        cnpj: '',
        endereco: '',
        celular: '',
        telefone: '',
        email: '',
        url_logo: '',
        imagem_logo: '',
        imagem_extra1: '',
        url_extra1: '',
      },
      showProgresso: false
    }
  },
  computed: {
    emailRules() {
      return this.validaEmail();
    }
  },
  methods: {
    async initialize() {
      try {
        this.showProgresso = true;

        let result = await getApi(api.comunidadeConsultar, this.$route.params.id)
        this.dados.nome = result.data.nome;
        this.dados.cnpj = result.data.cnpj;
        this.dados.endereco = result.data.endereco;
        this.dados.celular = result.data.celular;
        this.dados.telefone = result.data.telefone;
        this.dados.email = result.data.email;
        this.dados.url_logo = result.data.url_logo;
        this.dados.url_extra1 = result.data.url_extra1;
        this.showProgresso = false;
      } catch (erro) {
        console.error(erro);
        this.showProgresso = false;
      }

    },

    atualizarLogo(event) {
      var lerArquivo = new FileReader();
      lerArquivo.onload = (arquivoCarregado) => {
        this.dados.imagem_logo = arquivoCarregado.target.result;
      }
      lerArquivo.readAsDataURL(event);
    },

    atualizarExtra1(event) {
      var lerArquivo = new FileReader();
      lerArquivo.onload = (arquivoCarregado) => {
        this.dados.imagem_extra1 = arquivoCarregado.target.result;
      }
      lerArquivo.readAsDataURL(event);
    },



    saveCompleted(item) {
      this.$router.push({ name: 'Comunidade' });
    },
    close() {
      this.$router.push({ name: 'Comunidade' });
    },
  }
}
</script>

<style scoped></style>
