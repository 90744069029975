<template>
    <div>
      <v-card
        v-show="!showProgresso"
        max-width="1024"
        class="mx-auto my-10"
        style="background-color: #f8f9fa">
        <!-- Título do formulário -->
        <v-card-title style="background-color: #94896d;>">
          <span
            class="text-h6"
            style="color: #ffffff"
            >{{ titulo_formulario }}</span
          >
        </v-card-title>
        <hr />
  
        <v-card-text style="margin-top: -20px">
          <v-row>
            <v-col cols="12">
              <v-alert
                v-if="error != undefined"
                color="#efb300"
                border="left"
                elevation="2"
                colored-border
                style="background-color: #990000; color: white">
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
  
          <!-- Campos da tabela de preço -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                type="text"
                ref="descricao"
                label="* Descrição:"
                v-model="dados.descricao"
                autofocus
                color="#94896d"
                dense />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                label="Ano"
                :items="listaAnos"
                item-text="ano"
                item-value="ano"
                v-model="dados.ano"
                color="#94896d"
                dense />
            </v-col>
   
            <v-col cols="4">
              <v-select
                ref="faixa"
                label=" faixa:"
                :items="listFaixas"
                item-value="idfaixacontrato"
                item-text="descricao"
                v-model="dados.idfaixacontrato"
                @change="buscaVariacaoValorFaixa"
                color="#94896d"
                dense></v-select>
            </v-col>
  
            <v-col cols="4">
              <v-text-field
                type="text"
                ref="valor"
                label="Valor:"
                v-model="dados.valor"
                color="#94896d"
                dense>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
  
          <!-- Botão para cancelar o processo e não salvar os dados no banco de dados -->
          <v-btn
            color="#f5f4ec"
            dark
            class="mb-2"
            @click="close">
            <span style="color: #990000">Cancelar</span>
          </v-btn>
  
          <!-- Botão para salvar os dados do usuário no banco de dados -->
          <v-btn
            color="#990000"
            dark
            class="mb-2 mr-5"
            @click="salvar">
            <span style="color: #ffffff">Salvar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        v-show="!showProgresso"
        max-width="1024"
        class="mx-auto my-10"
        style="background-color: #f8f9fa">
        <!-- Título do formulário -->
        <v-card-title style="background-color: #94896d;>">
          <span class="text-h6" style="color: #ffffff">
            Relação dos anos próximos
          </span>
        </v-card-title> 
          <v-data-table
            :headers="headers"
            :items="listaFaixaAnos" 
            class="elevation-1"
          />       
      </v-card>
  
      <!-- Template para o temporizador de tela -->
      <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />
    </div>
</template>

<script>

import formatacaoMixin from '@/mixins/formatacao';
  import mixin from '@/store/mixin.js';
  import {api} from '@/network/Api';
import { getApi, postApi } from '@/network/ApiService';

  export default {
  mixins: [formatacaoMixin, mixin],
      components: {
      },
      created() {
        this.initialize();
      },
      data() {
        return {
          titulo_formulario: 'Adição de item na tabela de preço',
          
          listFaixas: [],
          listaAnos: [],
          dados: {
            descricao: '',
            ano: '',
            valor: 0,
            idfaixacontrato: ''
          },
          showProgresso: false,
          url_criar_dados: api.faixaValorCriar,
          nome_rota_listagem_dados: 'TabelasPreco',       
          error: undefined,

          headers: [
            { text: 'Ano', align: 'start', sortable: false, value: 'ano' },
            { text: 'Valor', value: 'valor' },
          ],
          listaFaixaAnos: []
          
        };
      },
      methods: {
        initialize() {
          this.showProgresso = true;
          this.configuraFiltroAno();
          this.consultarFaixas();
           
  
            setTimeout(() => {
            this.showProgresso = false;
            }, 100);
        
        },
        configuraFiltroAno() {
          var dataAtual = new Date();
          var anoAtual = dataAtual.getFullYear();
          this.anoSelecionado = anoAtual;
          var anos = [];
          // Adicionar -10 / atual / +5 anos
          for (var i = anoAtual - 10; i < anoAtual; i++) {
            anos.push(i);
          }
          anos.push(anoAtual);
          for (var j = anoAtual + 1; j <= anoAtual + 5; j++) {
            anos.push(j);
          }
          this.listaAnos = anos;
          this.dados.ano = anoAtual;
        },
  
        consultarFaixas() {
          
            getApi(api.faixaContratoListarTodas)
            .then((res) => {
              this.listFaixas = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        },
         
        close() {
          this.dialog = false;
          this.clearForm();
          this.initialize();
        },
        salvar() {
          this.error = null;
  
          if (this.dados.descricao == '') {
            this.error = 'O campo descrição é obrigatório';
  
            setTimeout(() => {
              this.error = undefined;
            }, 2000);
  
            return;
          } else if (this.dados.ano == '') {
            this.error = 'O campo ano é obrigatório';
  
            setTimeout(() => {
              this.error = undefined;
            }, 2000);
  
            return;
          } else {
             postApi(this.url_criar_dados, null,{
                  descricao: this.dados.descricao,
                  ano: this.dados.ano,
                  valor: parseFloat(this.dados.valor.replace(',', '.')),
                  idfaixacontrato: this.dados.idfaixacontrato,
                })
                .then((res) => {
                  if (res.data === 'Tudo ok!') {
                    (this.dados.descricao = ''),
                      (this.dados.ano = ''),
                      (this.dados.valor = ''),
                      (this.listFaixasTabelaPreco = []);
                  }
                  this.error = undefined;
                  this.close();
                })
                .catch((err) => {
                  var msgErro = err.response.data.err;
                  this.error = msgErro;
                });
            
          }
        },
        close() {
          this.$router.push({name: this.nome_rota_listagem_dados});
        },
        buscaVariacaoValorFaixa( ) {
          let id = this.dados.idfaixacontrato;
          if(id != null && id != '') {
              getApi(api.faixaValorConsultarVariacao, id)
              .then((res) => {
                this.listaFaixaAnos = res.data;
              })
              .catch((err) => {
                console.log(err);
            });
          }
        }
      },
    };
  </script>
  
  <style scoped>
    
</style>