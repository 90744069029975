<template>
  <div>
    <relatorio-view :showProgresso="showProgresso">
      <template v-slot:informacoes>
        <v-row v-if="contrato != null" class="pa-0">
          <v-col
            class="pa-0"
            cols="12"
          >
            <v-card
              class="mx-0"
              outlined
            >
              <v-list-item-content
                style="max-width: 100%"
                class="pa-6"
              >
                <v-row>
                  <v-col
                    cols="4"
                    class="text-overline py-0"
                  >
                    <span style="font-weight: bold">Número Contrato:</span>
                    {{ contrato.numero_contrato }}
                  </v-col>
                  <v-col
                    cols="8"
                    class="text-overline py-0"
                  >
                    <span style="font-weight: bold"> Nome do contrato: </span>
                    {{ contrato.nome_contrato }} ({{ idContrato }})
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="4"
                    class="text-overline py-0"
                  >
                    <span style="font-weight: bold">Assinatura: </span>
                    {{ formataData(dtAssinatura) }}
                  </v-col>
                  <v-col
                    cols="8"
                    class="text-overline py-0"
                  >
                    <span style="font-weight: bold">Vigência do contrato:
                    </span>
                    {{ formataData(dtValidadeInicio) }} até
                    {{ formataData(dtValidadeFim) }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="4"
                    class="text-overline py-0"
                  >
                    <span style="font-weight: bold">Faixa: </span>
                    {{ contrato.faixa?.descricao ?? '-' }}
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-overline py-0"
                  >
                    <span style="font-weight: bold">Total de Parcelas: </span>
                    {{ qtParcela }}
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-overline py-0"
                  >
                    <span style="font-weight: bold">Valor Total: </span>
                    {{ formataDinheiro(valorTotal) }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="text-overline py-0"
                  >
                    <span style="font-weight: bold">Observação:</span>
                    {{ observacao }}
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:dados>
        <v-row class="pa-0 ma-0">
          <v-col
            cols="10"
            offset="1"
          >
            <v-data-table
              fixed-header
              :items-per-page="-1"
              :headers="headers"
              :items="pagamentos"
              :search="search"
              :sort-by="coluna_ordenar"
              item-key="idpagamento"
              class="elevation-1 pa-0 ma-0"
              style="background-color: #f8f9fa"
              height="65vh"
            >
              <template v-slot:top>
                <v-card class="d-flex">
                  <v-card-title>Parcelas</v-card-title>
                  <v-spacer />

                  <div
                    v-if="edicaoPagamentoLote"
                    class="pa-2"
                  >
                    <v-card color="#ffbf00">
                      <v-card-title class="px-4 py-1">
                        Valor selecionado:
                        {{ formatarDinheiro(pagamentoLoteValorTotal) }}
                      </v-card-title>
                    </v-card>
                  </div>
                  <div
                    v-if="edicaoPagamentoLote"
                    class="pa-2"
                  >
                    <v-btn
                      elevation="2"
                      color="primary"
                      @click="abrirModalEdicaoLote()"
                    >
                      Salvar
                    </v-btn>
                  </div>
                  <div
                    class="pa-2"
                    outlined
                    tile
                  >
                    <template v-if="pagamentoLote">
                      <v-btn
                        elevation="auto"
                        color="#d2d6dd"
                        @click="cancelarPagamentoLote()"
                      >
                        Cancelar
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-1"
                        elevation="auto"
                        color="error"
                        @click="dialogoCancelamento = true"
                      >
                        <v-icon small>mdi-cancel</v-icon>
                        <span>Cancelamento</span>
                      </v-btn>
                      <v-btn
                        class="mx-1"
                        elevation="auto"
                        color="primary"
                        @click="pagamentoLote = !pagamentoLote"
                      >
                        <v-icon small>mdi-format-list-group</v-icon>
                        <span>Lote</span>
                      </v-btn>
                      <v-btn
                        :loading="loading"
                        class="mx-1"
                        elevation="auto"
                        color="primary"
                        @click="imprimir"
                      >
                        <v-icon small>mdi-printer-outline</v-icon>
                        <span>Imprimir</span>
                      </v-btn>
                    </template>
                  </div>
                </v-card>
              </template>
              <template v-slot:[`item.status`]="{item}">
                <v-chip
                  :color="getColor(item.status)"
                  dark
                >
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.parcela`]="{item}">
                {{ item.numero_parcelas }} / {{ item.parcela_final }}
              </template>

              <template v-slot:[`item.valor_restante`]="{item}">
                <v-chip
                  :color="getColorValor(item.valor_restante)"
                  dark
                >
                  {{ formataDinheiro(item.valor_restante) }}
                </v-chip>
              </template>

              <template v-slot:[`item.valor_original`]="{item}">
                {{ formataDinheiro(item.valor_original) }}
              </template>
              <template v-slot:[`item.valor_efetivo`]="{item}">
                {{ formataDinheiro(item.valor_efetivo) }}
              </template>

              <template v-slot:[`item.data_vencimento`]="{item}">
                {{ formataData(item.data_vencimento) }}
              </template>
              <template v-slot:[`item.data_pagamento`]="{item}">
                {{ formataData(item.data_pagamento) }}
              </template>
              <template v-slot:[`item.data_programado`]="{item}">
                {{ formataData(item.data_programado) }}
              </template>
              <template v-slot:[`item.tipo`]="{item}">
                {{ formataTipo(item.tipo) }}
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <template v-if="pagamentoLote">
                  <v-switch dense v-model="item.selecionado" :disabled="item.status_pagamento == 'CP'"/>
                </template>
                <template v-else>
                  <v-icon :disabled="item.status_pagamento != 'CP'" @click="imprimirParcela(item)" :loading="loading" class="mx-2" color="#344767">
                    mdi-printer-outline
                  </v-icon>
                  <v-icon @click="confirmarPagamento(item)" class="mx-2" color="#344767">
                    mdi-currency-usd
                  </v-icon>
                  <v-icon @click="abrirModalEdicao(item)" class="mx-2" color="#344767">
                    mdi-pencil
                  </v-icon>
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </relatorio-view>

    <v-dialog
      v-model="dialogoEditarPagamento"
      persistent
      max-width="1024"
    >
      <v-card>
        <v-card-title class="text-h5"> Pagamento </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-text-field
                label="Documento"
                :value="editarPagamento.documento"
                disabled
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Tipo"
                :value="formataTipo(editarPagamento.tipo)"
                disabled
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Valor Original"
                :value="formataDinheiro(editarPagamento.valor_original)"
                disabled
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Data Vencimento"
                :value="formataData(editarPagamento.data_vencimento)"
                disabled
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-select
                v-model="editarPagamento.idcaixa"
                :items="caixas"
                item-text="descricao"
                item-value="idcaixa"
                label="Caixa"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="editarPagamento.idconta"
                :items="contas"
                item-text="descricao"
                item-value="idconta"
                label="Conta"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="editarPagamento.idformapagamento"
                :items="formasPagamento"
                item-text="descricao"
                item-value="idformapagamento"
                label="Forma de Pagamento"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                filled
                name="input-7-4"
                label="Observação"
                v-model="editarPagamento.observacao"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <DialogData
                titulo="Data de pagamento:"
                v-model="editarPagamento.data_pagamento"
                disabled
              />
            </v-col>
            <v-col
              offset="1"
              cols="1"
              style="align-content: center; text-align: right"
            >
            </v-col>
            <v-col cols="4">
              <v-text-field
                disabled
                prepend-inner-icon="mdi-currency-usd"
                label="Valor Efetivo"
                v-model="editarPagamento.valor_efetivo"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="cancelarEdicao()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="salvarEdicao()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogoEditarPagamentoLote"
      persistent
      max-width="1024"
    >
      <v-card>
        <v-card-title class="text-h5"> Pagamento em lote </v-card-title>
        <v-card-text>
          <div style="border: 1px solid #9b9b9b">
            <span style="padding: 16px; font-size: 16px; color: black">Pagamentos selecionados:</span>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">Documento</th>
                  <th class="text-left">Tipo</th>
                  <th class="text-left">Valor</th>
                  <th class="text-left">Vencimento</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="pagamento in listaPagamentoSelecionadoLote"
                  :key="pagamento.idpagamento"
                >
                  <td>{{ pagamento.documento }}</td>
                  <td>{{ formataTipo(pagamento.tipo) }}</td>
                  <td>{{ formataDinheiro(pagamento.valor_original) }}</td>
                  <td>{{ formataData(pagamento.data_vencimento) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>

          <v-row>
            <v-col cols="4">
              <v-select
                v-model="itemPagamentoLote.idcaixa"
                :items="caixas"
                item-text="descricao"
                item-value="idcaixa"
                label="Caixa"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="itemPagamentoLote.idconta"
                :items="contas"
                item-text="descricao"
                item-value="idconta"
                label="Conta"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="itemPagamentoLote.idformapagamento"
                :items="formasPagamento"
                item-text="descricao"
                item-value="idformapagamento"
                label="Forma de Pagamento"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                filled
                name="input-7-4"
                label="Observação"
                v-model="itemPagamentoLote.observacao"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <DialogData
                titulo="Data de pagamento:"
                v-model="itemPagamentoLote.data_pagamento"
                disabled
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="cancelarEdicaoLote()"
          >
            Cancelar edição de lote
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="salvarEdicaoLote()"
          >
            Salvar edição de lote
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogoCancelamento"
      persistent
      max-width="540"
    >
      <v-card>
        <v-card-title class="text-h5">
          Cancelar as parcelas em aberto
        </v-card-title>
        <v-card-text>Você deseja cancelar todas as parcelas em aberto?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="dialogoCancelamento = false"
          >
            Não
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="confirmarCancelamentoParcelas()"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {format} from 'date-fns';
import DialogData from '@/components/dialogs/DialogData.vue';
import RelatorioView from '@/components/relatorios/RelatorioView.vue';

import {api} from '@/network/Api';
import {getApi, postApi} from '@/network/ApiService';

import mixin from '@/store/mixin.js';
import formatacaoMixin from '@/mixins/formatacao';
import geradorPDF from '@/mixins/geradorPDF';

export default {
  components: {
    RelatorioView,
    DialogData,
  },

  created() {
    this.initialize();
  },
  mixins: [formatacaoMixin, mixin, geradorPDF],
  data() {
    return {
      loading: false,
      pagamentoLote: false,
      edicaoPagamentoLote: false,
      pagamentoLoteValorTotal: 0,
      showProgresso: true,
      idregistro: this.$route.params.id,
      urlConsultarDados: api.contratoHistoricoAtual,
      idContratoHistorico: '',
      idContrato: '',
      dtAssinatura: '',
      dtValidadeInicio: '',
      ordem: '',
      dtValidadeInio: '',
      dtValidadeFim: '',
      valorTotal: '',
      qtParcela: '',
      observacao: '',
      contrato: '',
      contas: [],
      caixas: [],
      formasPagamento: [],
      coluna_ordenar: 'data_vencimento',
      pagamentos: [],
      search: '',
      headers: [
        {text: 'documento', value: 'documento'},
        {text: 'tipo', value: 'tipo'},
        // { text: 'data_movimentacao', value: 'data_movimentacao' },
        {text: 'Parcela', value: 'parcela'},
        {text: 'Vencimento', value: 'data_vencimento'},
        {text: 'Data Pagamento', value: 'data_pagamento'},
        {text: 'Valor Original', value: 'valor_original'},
        {text: 'valor Efetivo', value: 'valor_efetivo'},
        {text: 'valor Restante', value: 'valor_restante'},
        {text: 'Ações', value: 'actions', align: 'end'},
      ],
      dialogoEditarPagamento: false,
      dialogoEditarPagamentoLote: false,
      itemPagamentoLote: {
        idcaixa: '',
        idconta: '',
        idformapagamento: '',
        observacao: '',
        data_pagamento: '',
        idspagamentos: [],
      },
      dialogoCancelamento: false,
      editarPagamento: [],
      listaPagamentoSelecionadoLote: [],
    };
  },

  methods: {
    async initialize() {
      try {
        this.showProgresso = true;

        let respostaContas = await getApi(api.contaListarTodas);
        let respostaCaixa = await getApi(api.caixaListarTodas);
        let respostaFormaPag = await getApi(api.formaPagamentoListarTodas);

        this.contas = respostaContas.data;
        this.caixas = respostaCaixa.data;
        this.formasPagamento = respostaFormaPag.data;

        let currentData = await getApi(
          `${this.urlConsultarDados}/${this.idregistro}`
        );

        this.idContratoHistorico = currentData.data.idcontratohistorico;
        this.idContrato = currentData.data.idcontrato;
        this.dtAssinatura = currentData.data.dt_assinatura;
        this.ordem = currentData.data.ordem;
        this.dtValidadeInicio = currentData.data.dt_validade_inicio;
        this.dtValidadeFim = currentData.data.dt_validade_fim;
        this.valorTotal = currentData.data.valor_total;
        this.qtParcela = currentData.data.qt_parcela;
        this.observacao = currentData.data.observacao;
        this.contrato = currentData.data.contrato;
        this.pagamentos = currentData.data.pagamento;

        this.pagamentos.map((pagamento) => {
          this.$set(pagamento, 'selecionado');
          pagamento.selecionado = pagamento.status_pagamento == 'CP';
          if (
            pagamento.idformapagamento === null &&
            pagamento.status_pagamento != 'CP'
          ) {
            pagamento.idformapagamento = this.contrato.idformapagamento_padrao;
          }
          if (
            pagamento.idconta === null &&
            pagamento.status_pagamento != 'CP'
          ) {
            pagamento.idconta = this.contrato.idconta;
          }
        });
        this.showProgresso = false;
      } catch (err) {
        var msgErro = JSON.stringify(err);
        this.error = msgErro;
        this.showProgresso = false;
      }
    },

    formatarDinheiro(valor) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(valor);
    },

    cancelarPagamentoLote() {
      this.pagamentos.map((item) => {
        item.selecionado = false;
      });
      this.pagamentoLote = false;
    },

    async cancelarEdicaoLote() {
      this.dialogoEditarPagamentoLote = false;
      this.cancelarPagamentoLote();
      this.initialize();
    },

    async abrirModalEdicaoLote() {
      try {
        this.listaPagamentoSelecionadoLote = this.pagamentos.filter(
          (pagamento) =>
            pagamento.selecionado &&
            ['NP', 'CA'].includes(pagamento.status_pagamento)
        );
        // .map(item => item.idpagamento);

        this.itemPagamentoLote.idcaixa =
          this.listaPagamentoSelecionadoLote[0].idcaixa;
        this.itemPagamentoLote.idconta =
          this.listaPagamentoSelecionadoLote[0].idconta;
        this.itemPagamentoLote.idformapagamento =
          this.listaPagamentoSelecionadoLote[0].idformapagamento;
        this.itemPagamentoLote.observacao = `Pagamento em lote realizado no dia ${new Date().toLocaleString(
          'pt-BR',
          {timeZone: 'America/Sao_Paulo'}
        )}`;
        this.itemPagamentoLote.data_pagamento = format(
          new Date(),
          'yyyy-MM-dd'
        );

        this.dialogoEditarPagamentoLote = true;
      } catch (e) {
        console.log(e);
      }
      this.showProgresso = false;
    },

    async salvarEdicaoLote() {
      let listaPagamentos = this.pagamentos
        .filter(
          (pagamento) =>
            pagamento.selecionado &&
            ['NP', 'CA'].includes(pagamento.status_pagamento)
        )
        .map((item) => item.idpagamento);

      this.itemPagamentoLote.idspagamentos = listaPagamentos;

      await this.abirPDF(api.pagamentoLoteEditar, null, this.itemPagamentoLote);
      this.loading = false;
      this.dialogoEditarPagamentoLote = false;
      this.initialize();
    },

    // async gerarPDFLocal(response) {
    //   try {
    //     if (response.status == 200) {
    //       const byteCharacters = window.atob(response.data);
    //       const byteNumbers = new Array(byteCharacters.length);
    //       for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //       }
    //       const byteArray = new Uint8Array(byteNumbers);

    //       // Cria um objeto Blob a partir do array de bytes
    //       const blob = new Blob([byteArray], { type: 'application/pdf' });

    //       // Cria uma URL temporária para o Blob e inicia o download
    //       const pdfUrl = window.URL.createObjectURL(blob);
    //       window.open(pdfUrl, '_blank');
    //     } else {
    //       console.error(`Erro ao baixar o PDF. Status: ${response.status}`);
    //     }
    //   } catch (error) {
    //     console.error(`Falha ao baixar o PDF: ${error.message}`);
    //   }
    // },

    abrirModalEdicao(item) {
      this.editarPagamento = item;
      this.dialogoEditarPagamento = true;
    },

    async confirmarPagamento(item) {
      const response = await postApi(
        api.pagamentoConfirmar,
        item.idpagamento,
        item
      );
      this.initialize();
    },

    cancelarEdicao() {
      this.dialogoEditarPagamento = false;
    },
    async salvarEdicao() {
      const response = await postApi(
        api.pagamentoEditar,
        this.editarPagamento.idpagamento,
        this.editarPagamento
      );
      this.dialogoEditarPagamento = false;
      this.initialize();
    },

    async imprimir() {
      this.loading = true;
      await this.abirPDF(api.contratoHistoricoRelatorio, this.idContratoHistorico);
      this.loading = false;
    },
    async imprimirParcela(item) {
      this.loading = true;
      console.log(item)
      await this.abirPDF(api.pagamentoImprimir, item.idpagamento);
      this.loading = false;
    },
    async confirmarCancelamentoParcelas() {
      this.showProgresso = true;
      try {
        const response = await postApi(
          api.cancelarParcelasContrato,
          this.idregistro,
          null,
          null
        );
        this.initialize();
      } catch (e) {}
      this.showProgresso = false;
      this.dialogoCancelamento = false;
    },
  },
  watch: {
    pagamentos: {
      handler: function (after) {
        this.edicaoPagamentoLote = after?.some(
          (pagamento) =>
            pagamento.selecionado &&
            ['NP', 'CA'].includes(pagamento.status_pagamento)
        );
        this.pagamentoLoteValorTotal =
          after
            ?.filter(
              (pagamento) =>
                pagamento.selecionado &&
                ['NP', 'CA'].includes(pagamento.status_pagamento)
            )
            .reduce((acc, pagamento) => acc + pagamento.valor, 0) || 0;
      },
      deep: true,
    },
  },
};
</script>
