<template>
  <div>
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="#94896d"
        centered
        dark
        icons-and-text>
        <v-tabs-slider />
        <v-tab href="#tab-contrato">
          Contrato
          <v-icon>mdi-file-sign</v-icon>
        </v-tab>
        <v-tab href="#tab-renovacoes">
          Renovações
          <v-icon>mdi-invoice-list-outline</v-icon>
        </v-tab>
        <v-tab href="#tab-historico">
          Histórico
          <v-icon>mdi-clipboard-text-clock-outline</v-icon>
        </v-tab>
      </v-tabs>

      <span v-if="visivel">
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-contrato">
            <v-card flat>
              <EdicaoDadosV3>
                <template v-slot:formulario>
                  <v-row>
                    <v-col cols="3">
                      <v-text-field type="text" label="Número do contrato" v-model="dados.numero_contrato" autofocus color="#94896d" disabled />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field label="Nome do contrato" v-model="dados.nome_contrato" disabled />
                    </v-col>
                    <v-col cols="3">
                      <v-select v-model="dados.utiliza_cobrador" :items="listaCobrador" item-text="descricao" item-value="valor" color="#94896d" :disabled="!edicao" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-autocomplete label="Titular do contrato" v-model="dados.idpessoa_titular" :items="listaPessoas" item-value="idpessoa" item-text="nome" color="#94896d" :rules="titularRules" autofocus clearable :disabled="!edicao" />
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete label="Faixa do contrato" v-model="dados.idfaixacontrato" :items="listaFaixas" item-value="idfaixacontrato" item-text="descricao" color="#94896d" :rules="faixaRules" autofocus clearable :disabled="!edicao" />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <v-select v-model="dados.status" :items="listaStatus" item-text="descricao" item-value="valor" color="#94896d" disabled />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field label="Data Assinatura" v-model="dados.data_assinatura" disabled />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-if="dados.status != 1" label="Data Desligamento" v-model="dados.data_desligamento" disabled />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <v-select label="Forma de pagamento padrão" v-model="dados.idformapagamento_padrao" :items="listaFormasPagamento" item-text="descricao" item-value="idformapagamento" color="#94896d" :disabled="!edicao" />
                    </v-col>
                    <v-col cols="4">
                      <v-select label="Conta" v-model="dados.idconta" :items="listaContas" item-text="descricao" item-value="idconta" color="#94896d" :disabled="!edicao" />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-if="dados.status != 1" label="Data Desligamento" v-model="dados.data_desligamento" disabled />
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:acoes>
                  <template v-if="edicao">
                    <v-btn v-if="dados.status == 1" large style="color: #990000" @click="cancelarContratoView = true">
                      <v-icon>mdi-book-cancel-outline</v-icon>
                      <span>Inativar o contrato</span>
                    </v-btn> 

                    <v-btn large style="color: #990000" @click="cancelar">
                      <v-icon>mdi-cancel</v-icon>
                      <span>Cancelar</span>
                    </v-btn>

                    <v-btn large color="#990000" dark class="mx-5" @click="salvar">
                      <v-icon>mdi-content-save-outline</v-icon>
                      <span style="color: #ffffff">Salvar</span>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn :loading="loading" large color="#990000" dark class="mx-5" @click="actImprimir">
                      <v-icon>mdi-printer-outline</v-icon>
                      <span style="color: #ffffff">Imprimir</span>
                    </v-btn> 
                    <v-btn large color="#990000" dark class="mx-5" @click="edicao = true">
                      <v-icon>mdi-file-edit-outline</v-icon>
                      <span style="color: #ffffff">Editar</span>
                    </v-btn>
                  </template>
                </template>
              </EdicaoDadosV3>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-renovacoes">
            <v-card flat>
              <v-row>
                <v-col cols="12">
                  <ContratoHistoricoView :contrato="dados" />
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-historico">
            <v-card flat>
              <v-row>
                <v-col cols="12"> HISTORICO </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </span>
      <span v-else>
        <loading />
      </span>
    </v-card>
    <DialogoCancelamento :visivel="cancelarContratoView" @fecharDialogo="evtFecharDialogo" />
  </div>
</template>

<script>
  import EdicaoDadosV3 from '@/components/crud/EdicaoDadosV3';
  import DialogoCancelamento from '@/views/contrato/DialogoCancelamento';
  import ContratoHistoricoView from '@/views/contrato/historico/ContratoHistoricoView';
  import mixin from '@/store/mixin.js';
  import geradorPDF from '@/mixins/geradorPDF';
  import { api } from '@/network/Api';
  import { getApiv2, postApi } from '@/network/ApiService';
  import network from '@/mixins/network';

  export default {
    mixins: [mixin, geradorPDF, network],
    components: {
      EdicaoDadosV3,
      ContratoHistoricoView,
      DialogoCancelamento,
    },
    data() {
      return {
        tab: null,
        visivel: false,
        edicao: false,
        loading: false,
        cancelarContratoView: false,
        idregistro: this.$route.params.id,
        apiAtualizarRegistro: api.contratoAtualizar,
        apiImprimir: api.contratoImprimir,
        dados: null,
        listaFaixas: [],
        listaPessoas: [],
        listaFormasPagamento: [],
        listaContas: [],
        listaStatus: [],
        listaCobrador: [
           { valor: true, descricao: "Utiliza cobrador"}, 
           { valor: false, descricao: "Não utiliza cobrador"}, 
        ]
      };
    },
    created() {
      this.carregarDados();
    },

    computed: {
      numeroContratoRules() {
        return [(v) => !!v || 'O número do contrato é obrigatório'];
      },
      faixaRules() {
        return [(v) => !!v || 'Selecione a faixa do contrato'];
      },
      titularRules() {
        return [(v) => !!v || 'Selecione o titular do contrato'];
      },
      dataAssinaturaRules() {
        return [(v) => !!v || 'Informe a data de assinatura do contrato'];
      },
    },

    methods: {
      async carregarDados() {
        this.visivel = false;
        try {
          this.listaFaixas = await getApiv2(api.faixaContratoListarTodas);
          this.listaPessoas = await getApiv2(api.pessoaListarTodas);
          this.listaFormasPagamento = await getApiv2(api.formaPagamentoListarTodas);
          this.listaContas = await getApiv2(api.contaListarTodas);
          this.listaStatus = await getApiv2(api.contratoStatus);
          this.dados = await getApiv2(api.contratoConsultar, this.$route.params.id);
          setTimeout(() => {
            this.visivel = true;
          }, 100);
        } catch (err) {
          console.error(err);
        }
      },

      async salvar() {
        await this.salvarDados(this.apiAtualizarRegistro, this.idregistro, this.dados)
        this.edicao = false;
        this.carregarDados();
      },
      cancelar() {
        this.edicao = false;
      },
      async evtFecharDialogo(cancelarContrato) {
        if(cancelarContrato) {
          await postApi(api.contratoCancelar, this.$route.params.id);
        }
        this.cancelarContratoView = false;
        this.$router.push({ name: 'Contratos' });
      },
      async actImprimir() {
        this.loading = true;
        await this.abirPDF(this.apiImprimir, this.$route.params.id);
        this.loading = false;
      },
    },
  };
</script>
