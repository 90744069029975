<template>
  <div>
    <v-card v-show="!showProgresso" max-width="1024" class="mx-auto my-10" style="background-color: #f8f9fa">
      <!-- Título do formulário -->
      <v-card-title style="background-color: #94896d;">
        <v-row>
          <v-col cols="10">
            <span class="text-h6" style="color: #FFFFFF">{{ titulo_formulario }}</span>
          </v-col>
          <v-col v-if="podeImprimir" cols="1" offset="1">
            <v-icon large color="#fff" @click="imprimir">mdi-file-pdf-box</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <hr>
      <!-- Campos do formulário -->
      <v-card-text style="padding-bottom: 0px;">
        <v-container>
          <v-row>
            <!-- Campo descrição -->
            <v-col cols="12">
              <v-alert v-if="error != undefined" color="#FBCC38" elevation="2" colored-border>
                {{ error }}
              </v-alert>
              <slot></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <!-- Botões para salvar ou cancelar o processo de cadastro -->
      <v-card-actions>
        <v-spacer />

        <!-- Botão para cancelar o processo e não salvar os dados no banco de dados -->
        <v-btn color="#f5f4ec" dark class="mb-2" @click="close">
          <span style="color: #990000">Cancelar</span>
        </v-btn>

        <!-- Botão para salvar os dados do aluno no banco de dados -->
        <v-btn color="#990000" dark class="mb-2 mr-5" @click="salvar">
          <span style="color: #FFFFFF">Salvar</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Template para o temporizador de tela -->
    <tela-carregando :showProgresso="showProgresso" tituloTemporizador="Aguarde...:" />
  </div>
</template>

<script>
import { postApi } from '@/network/ApiService';
export default {
  components: {  },
  props: {
    idregistro: {
      type: String,
      required: true
    },
    titulo_formulario: {
      type: String,
      required: true
    }, 
    podeImprimir: {
      type: Boolean,
      default: false  
    },
    apiAtualizarRegistro: {
      type: String,
      required: true
    },
    campos_formulario: {
      type: Array | Object,
      required: true
    },
    showProgresso: {
      type: Boolean,
      default: false
    },
    formularioValido: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      error: undefined,
    }
  },
  methods: {
    isNumeric(value) {
      return /^-?\d+(\.\d+)?$/.test(value);
    },
    salvar() {
      let validacao = true;
      let formulario = this.campos_formulario;

      if (validacao) {
        if (formulario.valor) {
          if (this.isNumeric(formulario.valor)) {
            formulario.valor = formulario.valor;
          } else {
            formulario.valor = formulario.valor.replace(/\./g, "");
            formulario.valor = formulario.valor.replace(",", ".");
          }
        } else {
          formulario.valor = 0;
        }

        postApi(this.apiAtualizarRegistro, this.idregistro, formulario)
          .then((res) => {
            this.$emit('saveCompleted', res.data);

          }).catch(err => {
            console.log('err');
            if (err.message == 'Request failed with status code 500') {
              this.error = 'Falha no servidor contate o administrador do sistema!';
              setTimeout(() => { this.error = undefined; }, 4000);
            }
          });
      }
    },
    close() {
      this.$emit('close');
    },
    imprimir() {
      this.$emit('imprimir');
    },
  },
  watch: {
    formularioValido(newValue) {
      if (!newValue) {
        this.error = "Preencha todos os campos corretamente!"
        setTimeout(() => { this.error = undefined; }, 4000);
      }
    }
  },
}
</script>

<style scoped>
.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>