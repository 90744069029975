import { postApi } from '@/network/ApiService';

export default {
  data() {
    return {};
  },
  methods: {
    isNumeric(value) {
      return /^-?\d+(\.\d+)?$/.test(value);
    },
    async salvarDados(apiSalvar, idregistro, formulario) {
      try {
        if (formulario.valor) {
          if (this.isNumeric(formulario.valor)) {
            formulario.valor = formulario.valor;
          } else {
            formulario.valor = formulario.valor.replace(/\./g, '');
            formulario.valor = formulario.valor.replace(',', '.');
          }
        } else {
          formulario.valor = 0;
        }

        let res = await postApi(apiSalvar, idregistro, formulario);
        this.$emit('actSalvo', res.data);
      } catch (err) {
        if (err.message == 'Request failed with status code 500') {
          this.error = 'Falha no servidor contate o administrador do sistema!';
          setTimeout(() => {
            this.error = undefined;
          }, 4000);
        }
      }
    },
  },
};
