<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600">
    <v-card>
      <v-toolbar color="red" dark>
        Inativar o contrato
      </v-toolbar>
      <v-card-text>
        <div class="text-line pt-2">
          Caso você continue com o cancelamento, todas as parcelas futuras não pagas serão canceladas!
        </div>
        <div class="text-line pt-6">
          Deseja inativar o contrato atual?
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn outlined color="red" @click="fecharDialogo(false)">
          Não
        </v-btn>
        <v-btn outlined color="green" @click="fecharDialogo(true)">
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      visivel: {
        type: Boolean,
      },
    },
    data() {
      return {
        dialog: false,
      };
    },
    watch: {
      visivel(value) {
        this.dialog = value;
      },
    },
    methods: {
      fecharDialogo(acao) {
        this.$emit('fecharDialogo', acao);
      },
    },
  };
</script>

<style scoped>
  .text-line {
    font-size: 20px;
  }
</style>
