export default {
  data() {
    return {
      mixinData: 'mixin data',
    };
  },
  methods: {
    getColor(status) {
      return status ? 'green' : 'red';
    },
    getColorValor(valor) {
      return valor == '0.00' ? 'green' : 'red';
    },
    formataTipo(valor) {
      switch (valor) {
        case 'E':
          return 'Entrada';
        case 'S':
          return 'Saída';
        default:
          return '-';
      }
    },
    formataDinheiro(valor, coluna = null) {
      if (coluna != null) {
        valor = valor[coluna.value];
      }
      if (valor == null || valor == undefined) {
        return '-';
      }
      
      let valorNumerico = parseFloat(valor.toString().replace(/[^\d.-]/g, '')) || 0;
      valorNumerico = (valorNumerico ).toFixed(2) + '';
      valorNumerico = valorNumerico.replace('.', ',');
      valorNumerico = valorNumerico.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
      return valorNumerico;
    },
    formataData(dataOriginal, coluna = null) {
      if (coluna != null) {
        dataOriginal = dataOriginal[coluna.value];
      }
      if (dataOriginal == null || dataOriginal == undefined) {
        return '-';
      }

      const partesData = dataOriginal.split('-');
      if (partesData.length === 3) {
        const dia = partesData[2];
        const mes = partesData[1];
        const ano = partesData[0];

        return `${dia}/${mes}/${ano}`;
      }
      return dataOriginal;
    },
    formataStatusPagamento(valor) {
      const status = {NP: 'Não Pago', PP: 'Parcialmente Pago', CP: 'Pago'};
      return status[valor];
    },
  },
};
