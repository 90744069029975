<template>
  <div>
    <visualiza-dados-ano :cabecalhos_colunas="cabecalhos_colunas" :id_registro="id_registro"
      :titulo_listagem="titulo_listagem" :apiConsultarRegistros=apiConsultarRegistros
      :apiExcluirRegistro=apiExcluirRegistro :nome_rota_formulario_insercao="nome_rota_formulario_insercao"
      :nome_rota_formulario_edicao="nome_rota_formulario_edicao" :coluna_ordenar="coluna_ordenar"
      :formataColuna="formatarColuna" />
  </div>
</template>

<script>
import VisualizaDadosAno from '@/components/crud/VisualizaDadosAno.vue';
import { api } from '@/network/Api';

export default {
  components: {
    VisualizaDadosAno
  },
  data() {
    return {
      titulo_listagem: 'Lista da tabela de preços por faixa e ano',
      cabecalhos_colunas: [
        {
          text: 'Descrição',
          value: 'descricao',
          align: 'start',
          sortable: true,
          filterable: true
        },
        {
          text: 'Ano',
          value: 'ano',
          align: 'start',
          sortable: true,
          filterable: true
        },
        {
          text: 'Valor parcela',
          value: 'valor',
          align: 'start',
          sortable: true,
          filterable: true
        },
        {
          text: 'Faixa',
          value: 'idfaixacontrato',
          align: 'start',
          sortable: false,
          filterable: false
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      coluna_ordenar: 'descricao',
      apiConsultarRegistros: api.faixaValorListarTodos,
      apiExcluirRegistro: api.faixaValorExcluir,
      nome_rota_formulario_insercao: 'TabelaPrecoInsert',
      nome_rota_formulario_edicao: 'TabelaPrecoEdit',
      id_registro: 'idfaixavalor',
    }
  },
  methods: {
    formatarColuna(item, column) {
      if (column.value === 'idfaixacontrato') {
        item.idfaixacontrato = item.faixadescricao.descricao;
      }

      return item[column.value];
    }
  }
}
</script>

<style scoped></style>