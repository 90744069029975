<template>
  <v-app>
    <v-main>
      <v-container fluid style="padding: 0px;">
        <vue-progress-bar />
        <router-view style="max-width: 100%; padding: 0px; margin: 0px; overflow: hidden;"/>
        <footer-page texto_rodape="@techonne" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import FooterPage from '@/components/footer/FooterPage.vue'
export default {
  name: 'App',
  components: {
    FooterPage
  },
  data: () => ({
    //
  }),
};
</script>

<style>
  *{
    print-color-adjust: exact;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #94896d;
  }

  ::-webkit-scrollbar-thumb {
    background: #94896d;
  }
</style>