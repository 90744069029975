<template>
  <v-row justify="center">
    <v-dialog v-model="dialogoRenovacao" persistent max-width="800" >
      <v-card v-if="contrato != null">
        <v-card-title class="pa-1 ma-0" style="font-size: 1.4rem; background-color: #928a6dd9; color: #ffffff">
          Renovação de contrato
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="8">
              <v-text-field disabled label="Titular do contrato" v-model="contrato.nome_contrato" />
            </v-col>
            <v-col cols="4">
              <v-text-field disabled label="Faixa atual do contrato" v-model="contrato.faixa.descricao" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-autocomplete label="Forma de pagamento para a renovação" v-model="dadosRenovacaoContrato.idformaPagamento"
                item-value="idformapagamento" item-text="descricao" :items="listaFormasPagamento"
                color="#94896d" :rules="requiredRule" autofocus clearable />
            </v-col>

            <v-col cols="4">
              <v-autocomplete label="Conta para a renovação" v-model="dadosRenovacaoContrato.idconta"
                item-value="idconta" item-text="descricao" :items="listaContas"
                color="#94896d" :rules="requiredRule" autofocus clearable />
            </v-col>
            <v-col cols="4">
              <v-autocomplete label="Ano para a renovação" v-model="dadosRenovacaoContrato.ano"
                :items="listaAnos" color="#94896d" :rules="requiredRule" autofocus clearable @change="consultarValorParcelaAnuidade"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea filled name="Observação" v-model="dadosRenovacaoContrato.observacao" />
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12">
              <span v-if="valorAnuidade != null && valorAnuidade.descricao != null">
                <v-alert text type="success">
                  <span>{{ valorAnuidade.descricao }} :: valor da mensalidade: </span>
                  <money v-on="$listeners" disabled class="text-field-money" v-model="valorAnuidade.valor" v-bind="money" />
                </v-alert>
              </span>
              <span v-else>
                <v-alert text type="error">
                  Verifique se a anuidade para o ano e a faixa informados esta cadastrada!
                </v-alert>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="fecharDialogo()">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="efetivarRenovacao()" :disabled="!valorAnuidade?.valor">
            Renovar o contrato
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { api } from '@/network/Api';
  import { getApiv2, postApiv2 } from '@/network/ApiService';

  export default {
    props: {
      dialogoVisivel: {
        type: Boolean,
        default: false,
      },
      contrato: {
        type: Object,
        require: true,
      },
    },
    data() {
      return {
        dialogoRenovacao: false,
        listaFaixas: [],
        dadosRenovacaoContrato: {
          ano: new Date().getFullYear() + 1,
          idconta: null,
          idformaPagamento: null,
          observacao: `Renovação realizada em: ${new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}`,
        },
        listaAnos: [],
        listaFormasPagamento: [],
        listaContas: [],
        valorAnuidade: {},
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false,
        },
        anoAtual: new Date().getFullYear(),
        erroConsulta: '',
      };
    },
    computed: {
      requiredRule() {
        return [(v) => !!v || 'Obrigatório'];
      },
    },

    created() {
      this.initialize();
      this.gerarListaAnosProximos();
    },
    methods: {
      async initialize() {
        try { 
          this.listaContas = await getApiv2(api.contaListarTodas); 
          this.listaFormasPagamento = await getApiv2(api.formaPagamentoListarTodas);
          this.dadosRenovacaoContrato.idconta = this.contrato.idconta;
          this.dadosRenovacaoContrato.idformaPagamento = this.contrato.idformapagamento_padrao;
        } catch (erro) {
          console.error(erro);
        }
      },

      fecharDialogo() {
        this.$emit('fecharRenovacao');
      },

      async efetivarRenovacao() {
        try {
          this.erroConsulta = null;
          this.valorAnuidade = await postApiv2(api.contratoRenovar, this.contrato.idcontrato, this.dadosRenovacaoContrato);
          this.fecharDialogo();
        } catch (e) {
          this.erroConsulta = e.response.data.message;
        }
      },
      gerarListaAnosProximos() {
        this.listaAnos = [];

        for (let i = 0; i <= 5; i++) {
          this.listaAnos.push(this.anoAtual + i);
        }
      },
      async consultarValorParcelaAnuidade() {
        try {
          this.valorAnuidade = await postApiv2(api.faixaValorConsultarValor, this.contrato.idfaixacontrato, this.dadosRenovacaoContrato);
          this.erroConsulta = null;
        } catch (e) {
          console.log(e)
          console.error(e.response.data.message);
          this.valorAnuidade = null;
          this.erroConsulta = e.response.data.message;
        }
      },
    },
    watch: {
      dialogoVisivel(valor) {
        this.dialogoRenovacao = valor;
        if(valor) {      
          this.consultarValorParcelaAnuidade();
        }
      },
    },
  };
</script>

<style scoped>
  .subtitulo__historico__renovacao {
    font-size: 1.3rem;
    padding: 12px;
    margin: 0;
    background-color: #d5cfb9d9;
    color: #594e51;
    border-top: gray solid 1px;
  }
</style>
