<template>
  <v-layout justify-center>
    <div class="progresso-circular">
      <v-progress-circular indeterminate color="#94896d" :size="150" :width="10">
        <span style="display: block;text-align: center;margin-top: 15px;color: #94896d;" class="ma-0 ml-2">
          Aguarde
        </span>
      </v-progress-circular>
    </div>
  </v-layout>
</template>

<script>
export default {
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.centralizado {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Isso é opcional, dependendo do seu layout */
}

.progresso-circular {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: -200px;
  padding: 0px;
}
</style>
