import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginView from '@/views/autenticacao/LoginView.vue';
import HomeView from '@/views/HomeView.vue';

import PerfisView from '@/views/perfil/PerfisView.vue';
import InsertPerfilView from '@/views/perfil/InsertPerfilView.vue';
import EditPerfilView from '@/views/perfil/EditPerfilView.vue';

import UsuariosView from '@/views/usuario/UsuariosView.vue';
import InsertUsuarioView from '@/views/usuario/InsertUsuarioView.vue';
import EditUsuarioView from '@/views/usuario/EditUsuarioView.vue';

import ComunidadesView from '@/views/comunidade/ComunidadesView.vue';
import InsertComunidadeView from '@/views/comunidade/InsertComunidadeView.vue';
import EditComunidadeView from '@/views/comunidade/EditComunidadeView.vue';

import FaixasContratoView from '@/views/faixaContrato/FaixasContratoView.vue';
import InsertFaixaContratoView from '@/views/faixaContrato/InsertFaixaContratoView.vue';
import EditFaixaContratoView from '@/views/faixaContrato/EditFaixaContratoView.vue';

import TabelasPrecoView from '@/views/tabelaPreco/TabelasPrecoView.vue';
import InsertTabelaPrecoView from '@/views/tabelaPreco/InsertTabelaPrecoView.vue';
import EditTabelaPrecoView from '@/views/tabelaPreco/EditTabelaPrecoView.vue';

import ContasView from '@/views/conta/ContasView.vue';
import InsertContaView from '@/views/conta/InsertContaView.vue';
import EditContaView from '@/views/conta/EditContaView.vue';

import CaixasView from '@/views/caixa/CaixasView.vue';
import InsertCaixaView from '@/views/caixa/InsertCaixaView.vue';
import EditCaixaView from '@/views/caixa/EditCaixaView.vue';

import FormasPagamentoView from '@/views/formaPagamento/FormasPagamentoView.vue';
import InsertFormaPagamentoView from '@/views/formaPagamento/InsertFormaPagamentoView.vue';
import EditFormaPagamentoView from '@/views/formaPagamento/EditFormaPagamentoView.vue';

import PessoasView from '@/views/pessoa/PessoasView.vue';
import InsertPessoaView from '@/views/pessoa/InsertPessoaView.vue';
import EditPessoaView from '@/views/pessoa/EditPessoaView.vue';

import ContratoView from '@/views/contrato/ContratoView.vue';
import InsertContratoView from '@/views/contrato/InsertContratoView.vue';
import EditContratoView from '@/views/contrato/EditContratoView.vue';

import EditContratoHistorico from '@/views/ContratoHistorico/EditContratoHistorico.vue';

import PagamentoView from '@/views/pagamento/PagamentoView.vue';
import InsertPagamentoView from '@/views/pagamento/InsertPagamentoView.vue';
import EditPagamentoView from '@/views/pagamento/EditPagamentoView.vue';

import TransferenciaView from '@/views/transferencia/TransferenciaView.vue';
import InsertTransferenciaView from '@/views/transferencia/InsertTransferenciaView.vue';
import EditTransferenciaView from '@/views/transferencia/EditTransferenciaView.vue';

import LivroCaixa from '@/views/LivroCaixa/LivroCaixa.vue';

import RelatorioAniversario from '@/views/relatorios/RelatorioAniversario.vue';
import RelatorioBatismo from '@/views/relatorios/RelatorioBatismo.vue';
import RelatorioConfirmacao from '@/views/relatorios/RelatorioConfirmacao.vue';
import RelatorioFaixaEtaria from '@/views/relatorios/RelatorioFaixaEtaria.vue';
import RelatorioMembrosPorFaixa from '@/views/relatorios/RelatorioMembrosPorFaixa.vue';

import RelatorioInadimplentes from '@/views/relatorios/RelatorioInadimplentes.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {title: 'Login', isAuth: false},
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    meta: {title: 'Home', isAuth: false},
    children: [
      {
        path: '/perfis',
        name: 'Perfis',
        component: PerfisView,
      },
      {
        path: '/perfil/criar',
        name: 'PerfilInsert',
        component: InsertPerfilView,
      },
      {
        path: '/perfil/editar/:id',
        name: 'PerfilEdit',
        component: EditPerfilView,
      },
      {
        path: '/usuarios',
        name: 'Usuarios',
        component: UsuariosView,
      },
      {
        path: '/usuario/criar',
        name: 'UsuarioInsert',
        component: InsertUsuarioView,
      },
      {
        path: '/usuario/editar/:id',
        name: 'UsuarioEdit',
        component: EditUsuarioView,
      },
      {
        path: '/comunidade',
        name: 'Comunidade',
        component: ComunidadesView,
      },
      {
        path: '/comunidade/criar',
        name: 'ComunidadeInsert',
        component: InsertComunidadeView,
      },
      {
        path: '/comunidade/editar/:id',
        name: 'ComunidadeEdit',
        component: EditComunidadeView,
      },
      {
        path: '/faixas-contrato',
        name: 'FaixasContrato',
        component: FaixasContratoView,
      },
      {
        path: '/faixa-contrato/criar',
        name: 'FaixaContratoInsert',
        component: InsertFaixaContratoView,
      },
      {
        path: '/faixa-contrato/editar/:id',
        name: 'FaixaContratoEdit',
        component: EditFaixaContratoView,
      },
      {
        path: '/tabelas-preco',
        name: 'TabelasPreco',
        component: TabelasPrecoView,
      },
      {
        path: '/tabela-preco/criar',
        name: 'TabelaPrecoInsert',
        component: InsertTabelaPrecoView,
      },
      {
        path: '/tabela-preco/editar/:id',
        name: 'TabelaPrecoEdit',
        component: EditTabelaPrecoView,
      },
      {
        path: '/contas',
        name: 'Contas',
        component: ContasView,
      },
      {
        path: '/conta/criar',
        name: 'ContaInsert',
        component: InsertContaView,
      },
      {
        path: '/conta/editar/:id',
        name: 'ContaEdit',
        component: EditContaView,
      },
      {
        path: '/caixas',
        name: 'Caixas',
        component: CaixasView,
      },
      {
        path: '/caixa/criar',
        name: 'CaixaInsert',
        component: InsertCaixaView,
      },
      {
        path: '/caixa/editar/:id',
        name: 'CaixaEdit',
        component: EditCaixaView,
      },
      {
        path: '/formas-pagamento',
        name: 'FormasPagamento',
        component: FormasPagamentoView,
      },
      {
        path: '/forma-pagamento/criar',
        name: 'FormaPagamentoInsert',
        component: InsertFormaPagamentoView,
      },
      {
        path: '/forma-pagamento/editar/:id',
        name: 'FormaPagamentoEdit',
        component: EditFormaPagamentoView,
      },
      {
        path: '/pessoas',
        name: 'Pessoas',
        component: PessoasView,
      },
      {
        path: '/pessoa/criar',
        name: 'PessoaInsert',
        component: InsertPessoaView,
      },
      {
        path: '/pessoas/editar/:id',
        name: 'PessoaEdit',
        component: EditPessoaView,
      },
      {
        path: '/contratos',
        name: 'Contratos',
        component: ContratoView,
      },
      {
        path: '/contrato/criar',
        name: 'ContratoInsert',
        component: InsertContratoView,
      },
      {
        path: '/contrato/editar/:id',
        name: 'ContratoEdit',
        component: EditContratoView,
      },
      {
        path: '/contrato/historico/:id',
        name: 'EditContratoHistorico',
        component: EditContratoHistorico,
      },
      {
        path: '/pagamentos',
        name: 'Pagamentos',
        component: PagamentoView,
      },
      {
        path: '/pagamento/criar',
        name: 'PagamentoInsert',
        component: InsertPagamentoView,
      },
      {
        path: '/pagamento/editar/:id',
        name: 'PagamentoEdit',
        component: EditPagamentoView,
      },
      {
        path: '/transferencia',
        name: 'Transferencia',
        component: TransferenciaView,
      },
      {
        path: '/transferencia/criar',
        name: 'TransferenciaInsert',
        component: InsertTransferenciaView,
      },
      {
        path: '/transferencia/editar/:id',
        name: 'TransferenciaEdit',
        component: EditTransferenciaView,
      },
      {
        path: '/livro-caixa',
        name: 'LivroCaixa',
        component: LivroCaixa,
      },

      {
        path: '/relatorios/aniversarios',
        name: 'RelatorioAniversario',
        component: RelatorioAniversario,
      },
      {
        path: '/relatorios/batismos',
        name: 'RelatorioBatismo',
        component: RelatorioBatismo,
      },
      {
        path: '/relatorios/confirmacoes',
        name: 'RelatorioConfirmacao',
        component: RelatorioConfirmacao,
      },
      {
        path: '/relatorios/faixaEraria',
        name: 'RelatorioFaixaEtaria',
        component: RelatorioFaixaEtaria,
      },
      {
        path: '/relatorios/inadimplentes',
        name: 'RelatorioInadimplentes',
        component: RelatorioInadimplentes,
      },
      {
        path: '/relatorios/membrosPorFaixa',
        name: 'RelatorioMembrosPorFaixa',
        component: RelatorioMembrosPorFaixa,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
