<template>
  <div>
    <v-dialog v-model="dialogoVisivel" persistent width="1024px">
      <v-card>
        <v-toolbar color="primary" dark>{{ tituloModal }}</v-toolbar>
        <div style="color: #0f180f;padding: 14px 32px;border: 1px solid;">
          Saldo anterior: {{ 'R$ ' + formataDinheiro(saldoAnterior) }}
        </div>

        <v-card-text>

          <v-data-table :headers="cabecalhos" :items="registros" :sort-by="colunaOrdenar" class="elevation-1"
            style="background-color: #f8f9fa" fixed-header>
            <template v-slot:item="{ item }">
              <tr>
                <td v-for="column in cabecalhos" :key="column.value">
                  <span :style="getStyle(item[column.value], column)">
                    {{ formatValue(item[column.value], column) }}
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <div style="color:#0f180f;padding: 14px 32px;border: 1px solid;">
          Saldo final: {{ 'R$ ' + formataDinheiro(saldoFinal) }}
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined @click="fecharDialogo">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import formatacaoMixin from '@/mixins/formatacao';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';

export default {
  mixins: [formatacaoMixin],
  components: {},
  props: {
    dialogoVisivel: { require: true },
    filtros: { require: true },
    colunaOrdenar: { default: "id" },
    saldoAnterior: { default: 0 },
    saldoFinal: { default: 0 },
  },
  data: () => ({
    registros: [],
    cabecalhos: [],
    apiConsultarRegistrosDetalhados: api.livroCaixaDetalhado,
    tituloModal: "Movimentações"
  }),
  methods: {
    async buscarDadosDetalhados() {
      this.tituloModal = `Movimentações para o dia ${this.formataData(this.filtros.data)}`
      let res = await getApi(this.apiConsultarRegistrosDetalhados, null, this.filtros)
      this.registros = res.data.dados;
      this.cabecalhos = res.data.header;
      setTimeout(() => { this.showProgresso = false; }, 100);
    },

    fecharDialogo() {
      this.$emit("fecharDialogo");
    },
    getStyle(value, column) {
      if (['valor'].includes(column.value) && value > 0) {
        return { color: 'forestgreen' };
      } else if (['valor'].includes(column.value) && value < 0) {
        return { color: 'red' };
      } else {
        return { color: 'grey' };
      }
      return {};
    },
    formatValue(value, column) {
      if (column.type === 'date') {
        return this.formataData(value);
      } else if (column.type === 'money') {
        return 'R$ ' + this.formataDinheiro(value);
      } else if (column.type === 'status_pagamento') {
        console.log(value)
        if (value == 'CP' || value == 'TP') {
          return 'Pago';
        }
      }
      return value;
    },
  },

  watch: {
    dialogoVisivel(newVal) {
      if (newVal) {
        this.buscarDadosDetalhados();
      }
    }
  }
}

</script>