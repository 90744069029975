<template>
  <div>
    <visualiza-dados-listagem :id_registro="id_registro" :titulo_listagem="titulo_listagem"
      :apiConsultarRegistros="apiConsultarRegistros" :apiExcluirRegistro="apiExcluirRegistro"
      :coluna_ordenar="coluna_ordenar" @cadastrarItem="cadastrarItem" @editarItem="editarItem"
      :mostra_botao_cadastrar="mostra_botao_cadastrar" :filtros="filtros">

      <template v-slot:filtros>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <CampoData titulo="Início do intervalo" v-model="filtros.dataInicio" />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <CampoData titulo="Fim do intervalo" v-model="filtros.dataFim" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-select v-model="filtros.idContaOrigem" color="blue" hide-details :items="listaContas"
              item-text="descricao" item-value="idconta" label="Conta Origem" persistent-hint clearable />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select v-model="filtros.idContaDestino" color="blue" hide-details :items="listaContas"
              item-text="descricao" item-value="idconta" label="Conta destino" persistent-hint clearable />
          </v-col>
        </v-row>
      </template>

      <template v-slot:topo>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn @click="imprimir()">
            <v-icon>mdi-printer-outline</v-icon>
            Imprimir
          </v-btn>
        </v-toolbar>
      </template>
    </visualiza-dados-listagem>
  </div>
</template>

<script>
import RelatorioView from '@/components/relatorios/RelatorioView.vue';
import visualizaDadosListagem from '@/components/crud/VisualizaDadosListagem.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';
import { format, parseISO } from 'date-fns'

export default {
  components: {
    RelatorioView,
    visualizaDadosListagem,
  },
  data() {
    return {
      titulo_listagem: 'Relatório de transferências entre contas',
      search: '',
      coluna_ordenar: 'data_movimentacao',
      apiContas: api.contaListarTodas,
      registros: null,
      listaContas: [],
      cabecalhos_colunas: [],
      apiConsultarRegistros: api.transferenciaListarTodas,
      apiImprimirRegistros: api.transferenciaListarTodasImprimir,
      apiExcluirRegistro: api.transferenciaExcluir,
      id_registro: 'idtransferencia',
      mostra_botao_cadastrar: true,


      filtros: {
        dataInicio: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        dataFim: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        idContaOrigem: '',
        idContaDestino: '',
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.buscarContas();
    },
    buscarContas() {
      getApi(api.contaListarTodas)
        .then((res) => {
          this.listaContas = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    buscarDados() {
      getApi(this.apiConsultarRegistros, null, this.filtros)
        .then((res) => {
          this.registros = res.data.dados;
          this.cabecalhos_colunas = res.data.header;
        })
        .catch((err) => { })
        .finally(() => {
          setTimeout(() => {
            this.showProgresso = false;
          }, 100);
        });
    },
    formataColuna(item, column) {
      return item[column.value];
    },
    deleteItem(item) {
      // Coleta o registro seleciondo para edição
      this.editedItem = Object.assign({}, item)
      // Coleta o indice do resgitro selecionado pera edição
      this.primarykey = this.editedItem[this.id_registro];
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      // Exclui registro no banco de dados
      excluirApi(this.apiExcluirRegistro, this.primarykey)
        .then(() => {
          this.closeDelete();
        })
        .catch(err => {
          let textoErro = err.response.data.detail;
          let posicao = textoErro.search('is still referenced from table');
          if (posicao > 0) {
            this.mensagemAlerta = 'Este registro não pode ser excluído!';
          } else {
            this.mensagemAlerta = 'Erro ao excluir, tente novamente mais tarde!';
          }
          this.dialogMensagemALerta = true;
          this.closeDelete();
        });
    },
    close() {
      this.dialog = false;
      this.primarykey = -1
      this.clearForm();
      this.initialize();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.primarykey = -1;
      this.initialize();
      this.mostra_botao_cadastrar = true;
    },
    cadastrarItem() {
      this.$router.push({ name: 'TransferenciaInsert' });
    },
    editarItem(params) {
      this.$router.push({ name: 'TransferenciaEdit', params: { id: params.id } });
    },
    async imprimir() {
      console.log("iniciando download");
      let nomeArquivo = "relatorio";
      try {
        const response = await getApi(this.apiImprimirRegistros, null, this.filtros, {
          responseType: 'blob',
        });
        if (response.status === 200) {
          const base64 = response.data;
          const filename = `${nomeArquivo}.pdf`;
          // Converte a string Base64 para um array de bytes
          const byteCharacters = window.atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Cria um objeto Blob a partir do array de bytes
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Cria uma URL temporária para o Blob e inicia o download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          console.error(`Erro ao baixar o PDF. Status: ${response.status}`);
        }
      } catch (error) {
        console.error(`Falha ao baixar o PDF: ${error.message}`);
      }
    },
  },

};
</script>

<style scoped></style>
