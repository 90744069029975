import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from './vuetify/locale/pt.ts';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {pt},
    current: 'pt',
  },
  theme: {dark: false},
  dataTable: {
    itemsPerPage: 15,
    page: 1,
  },
});
