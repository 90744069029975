<template>
  <div>
    <insere-dados 
      :titulo_formulario="tituloFormulario" 
      :apiInserirRegistro="apiInserirRegistro"
      :campos_formulario="formulario"
      @saveCompleted="saveCompleted" 
      @close="close"
    >
      <v-text-field 
        :label="formulario[0].label" 
        type="text" 
        v-model="formulario[0].valor" 
        autofocus
        color="#94896d" 
        :rules="nomeRules"
      />

      <v-text-field
        :label="formulario[1].label" 
        type="text" 
        ref="email" 
        v-model="formulario[1].valor" 
        autofocus
        color="#94896d" 
        :rules="emailRules"
      />
      
      <v-text-field 
        :label="formulario[2].label" 
        :type="showPassword ? 'text' : 'password'"
        v-model="formulario[2].valor" 
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="togglePasswordVisibility" 
        autofocus color="#94896d" 
        :rules="passwordRules"
      />
      
      <v-select 
        :label="formulario[3].label"
        item-color="#7513BF" clearable 
        :items="perfis" item-text="descricao"
        item-value="idperfil"
        v-model="formulario[3].valor" 
        :rules="perfilRules" />
    </insere-dados>
  </div>
</template>

<script>
import mixin from '@/store/mixin.js';
import InsereDados from '@/components/crud/InsereDados.vue';
import { api } from '@/network/Api';
import { getApi } from '@/network/ApiService';

export default {
  mixins: [mixin],
  components: {
    InsereDados
  },
  data() {
    return {
      showPassword: false,
      nome_rota_listagem_dados: 'Usuarios',
      tituloFormulario: 'Cadastro de usuário',
      apiInserirRegistro: api.usuarioCriar,
      
      formulario: [
        { label: '* Nome', nome_campo: 'name', name: '', obrigatorio: true },
        { label: '* E-mail', nome_campo: 'email', email: '', obrigatorio: true },
        { label: '* Senha', nome_campo: 'password', password: '', obrigatorio: true },
        { label: '* Perfil', nome_campo: 'idperfil', idperfil: '', obrigatorio: true }
      ],
      perfis: []
    }
  },
  created() {
    this.initialize();
  },
  
  computed: {
    nomeRules() {
      return [
        v => !!v || "O nome é obrigatório",
        v => !v || v.length > 3 || 'O Nome deverá possuir ao menos 3 caracteres'
      ];
    },
    emailRules() {
      return this.validaEmail();
    },
    passwordRules() {
      return [
        v => !!v || "A senha é obrigatória",
        v => !v || v.length > 6 || 'A senha deverá possuir ao menos 6 caracteres'
      ];
    },
    perfilRules() {
      return [
        v => !!v || "O perfil é obrigatório",
      ];
    },
  },
  methods: {
    saveCompleted(item) {
      this.$router.push({ name: 'Usuarios' });
    },
    close() {
      this.$router.push({ name: 'Usuarios' });
    },
    initialize() {
      getApi(api.perfilListarTodos)
        .then(res => {
          this.perfis = res.data;
        })
        .catch(err => { });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  }
}
</script>

<style scoped></style>